import { makeAutoObservable } from 'mobx';

export class Category {
    id: number;
    name?: string;
    safeForWork: boolean;
    syncApi?: boolean;

    static fromJS(object: Category): Category {
        return new Category(object.id, object.safeForWork, object.name, object.syncApi);
    }

    constructor(id: number, safeForWork: boolean, name?: string, syncApi?: boolean) {
        makeAutoObservable(this);

        this.id = id;
        this.safeForWork = safeForWork;
        this.name = name;
        this.syncApi = syncApi;
    }
}

export default Category;
