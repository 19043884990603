import * as React from 'react';
import classNames from 'classnames';
import DateTime, { dateTime } from '../util/DateTime';
import Blacklist from './Blacklist';
import { RootStoreProp } from '../../stores/RootStore';
import * as ModelFeed from '../../stores/model/Feed';
import { inject, observer } from 'mobx-react';
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faQuestion, faSyncAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
    AddonFormField,
    Button,
    Checkbox,
    FormField,
    Input,
    Select,
    StaticInput,
    Textarea,
    Tooltip,
    TooltipText
} from '../bulma';
import { values } from 'lodash';

@inject('rootStore')
@observer
class Feed extends React.Component<RootStoreProp & { feed: ModelFeed.Feed }, {}> {

    feedExpanded: boolean = false;

    constructor(props: RootStoreProp & { feed: ModelFeed.Feed }) {
        super(props);

        makeObservable(this, {
            feedExpanded: observable,
            toggleFeedCollapse: action.bound,
            feedPause: action.bound,
            feedUnpause: action.bound
        });

        reaction(
            () => values(this.props.feed),
            () => this.props.rootStore!.feedStore.updateFeed(this.props.feed),
            {delay: 400});
    }

    toggleFeedCollapse() {
        this.feedExpanded = !this.feedExpanded;
    }

    feedPause() {
        this.props.feed.pause = true;
    }

    feedUnpause() {
        this.props.feed.pause = false;
    }

    reactivate() {
        this.props.rootStore!.feedStore.reactivateFeed(this.props.feed.id);
    }

    deleteFeed() {
        this.props.rootStore!.feedStore.deleteFeed(this.props.feed);
    }

    startFeedUpdate() {
        this.props.rootStore!.feedStore.updateSingleFeed(this.props.feed.id);
    }

    render() {
        let uiState = this.props.rootStore!.uiStateStore;
        let feed = this.props.feed;

        return (
            <div className="card">
                <div
                    className={classNames(
                        'card-header',
                        {'is-danger': feed.hasError},
                        {'is-light': !feed.active && !feed.hasError}
                    )}>
                    <div className="card-header-title" onClick={this.toggleFeedCollapse}>
                        {feed.name}
                    </div>
                    <div className="card-header-right">
                        <small onClick={this.feedUnpause}
                               className={classNames({'is-hidden': !this.props.feed.active})}>
                            <FontAwesomeIcon icon={faPlay} size={'sm'}
                                             className={classNames('has-text-success', 'has-light-opaque-text', {'has-text-grey': this.props.feed.pause})}/>
                        </small>
                        <small onClick={this.feedPause}
                               className={classNames({'is-hidden': !this.props.feed.active})}>
                            <FontAwesomeIcon icon={faPause} size={'sm'}
                                             className={classNames('has-text-warning', 'has-light-opaque-text', {'has-text-grey': !this.props.feed.pause})}/>
                        </small>
                        <small className="has-text-grey" onClick={(e) => {
                            this.startFeedUpdate();
                            e.stopPropagation();
                        }} title="Feed aktualisieren">
                            <FontAwesomeIcon icon={faSyncAlt} spin={this.props.feed.isUpdating} size={'sm'}/>
                            <small style={{paddingLeft: '4px'}}>
                                <DateTime dateTime={feed.pubDate}/>
                            </small>
                        </small>
                    </div>
                </div>
                <div className={classNames('card-content', {'is-hidden': !this.feedExpanded})}>
                    {(this.feedExpanded) ? (
                        <div className="columns">
                            <div className="column">
                                <FormField label="Name">
                                    <Input value={feed.name} onChange={(newValue) => this.props.feed.name = newValue}/>
                                </FormField>
                                <FormField label="URL">
                                    <Input value={feed.url} onChange={(newValue) => this.props.feed.url = newValue}/>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Aktiv" value={feed.active}
                                              onChange={(newValue) => this.props.feed.active = newValue}/>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Feed Pre-Process (nur RSS)" value={feed.preprocess}
                                              onChange={(newValue) => this.props.feed.preprocess = newValue}/>
                                </FormField>
                                <FormField label="" hidden={!feed.preprocess}>
                                    <div className="field">
                                        <span>Instruktionen/Regeln (1 pro Zeile) </span>
                                        <Tooltip>
                                            <FontAwesomeIcon icon={faQuestion}/>
                                            <TooltipText>
                                                <p><i>replace(oldValue, newValue)</i></p>
                                                <p>Ersetzen von Text (kann ohne " eingegeben werden) durch einen anderen
                                                    Wert.</p>
                                                <br/>
                                                <p><i>regex_replace(regexOld, newValue)</i></p>
                                                <p>Ersetzen von Regex (Achtung: keine "," verwenden!) durch anderen
                                                    Wert, welcher aber auch $ enthalten kann für Match Ersetzungen</p>
                                            </TooltipText>
                                        </Tooltip>
                                        <Textarea value={feed.preprocessRules}
                                                  onChange={(newValue) => this.props.feed.preprocessRules = newValue}/>
                                    </div>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Artikel Website verwenden (nur RSS)" value={feed.followUrl}
                                              onChange={(newValue) => this.props.feed.followUrl = newValue}/>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Artikel Website Process (nur RSS)" value={feed.followProcess}
                                              onChange={(newValue) => this.props.feed.followProcess = newValue}/>
                                </FormField>
                                <FormField label="" hidden={!feed.followProcess}>
                                    <div className="field">
                                        <span>Instruktionen/Regeln (1 pro Zeile) </span>
                                        <Tooltip>
                                            <FontAwesomeIcon icon={faQuestion}/>
                                            <TooltipText>
                                                <p><i>replace(oldValue, newValue)</i></p>
                                                <p>Ersetzen von Text (kann ohne " eingegeben werden) durch einen anderen
                                                    Wert.</p>
                                                <br/>
                                                <p><i>regex_replace(regexOld, newValue)</i></p>
                                                <p>Ersetzen von Regex (Achtung: keine "," verwenden!) durch anderen
                                                    Wert, welcher aber auch $ enthalten kann für Match Ersetzungen</p>
                                            </TooltipText>
                                        </Tooltip>
                                        <Textarea value={feed.followProcessRules}
                                                  onChange={(newValue) => this.props.feed.followProcessRules = newValue}/>
                                    </div>
                                </FormField>
                                <FormField label="" hidden={!feed.followUrl}>
                                    <div className="field">
                                        <span>Inhalts Selektoren (1 pro Zeile)</span>
                                        <Textarea value={feed.followSelectors}
                                                  onChange={(newValue) => this.props.feed.followSelectors = newValue}/>
                                    </div>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Bilder Feed" value={feed.imageFeed}
                                              onChange={(newValue) => this.props.feed.imageFeed = newValue}/>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Safe for Work" value={feed.safeForWork}
                                              onChange={(newValue) => this.props.feed.safeForWork = newValue}/>
                                </FormField>
                                <FormField label="">
                                    <Checkbox text="Sync API" value={feed.syncApi}
                                              onChange={(newValue) => this.props.feed.syncApi = newValue}/>
                                </FormField>
                                <FormField label="Fetcher">
                                    <Select onChange={(newValue) => this.props.feed.fetcher = newValue}
                                            value={feed.fetcher}>
                                        <option value="rss">RSS/Atom</option>
                                        <option value="tumblr">Tumblr</option>
                                        <option value="instagram">Instagram</option>
                                    </Select>
                                </FormField>
                                <FormField label="Zuletzt abgeholt">
                                    <StaticInput value={dateTime(feed.lastUpdated)}/>
                                </FormField>
                            </div>
                            <div className="column">
                                <FormField label="Kategorie">
                                    <Select onChange={(newValue) => this.props.feed.categoryId = Number(newValue)}
                                            value={String(feed.categoryId)}>
                                        {this.props.rootStore!.categoryStore.get()
                                            .map((category) => {
                                                return (
                                                    <option key={category.id}
                                                            value={category.id}>{category.name}</option>
                                                );
                                            })}
                                    </Select>
                                </FormField>
                                <AddonFormField label="Löschen nach..." addon="Tagen">
                                    <Input value={String(feed.deleteAfterDays)}
                                           onChange={(newValue) => this.props.feed.deleteAfterDays = Number(newValue)}/>
                                </AddonFormField>
                                <AddonFormField label="Update nach..." addon="Minuten">
                                    <Input value={String(feed.updateInterval)}
                                           onChange={(newValue) => this.props.feed.updateInterval = Number(newValue)}/>
                                </AddonFormField>
                                <FormField label="Letzter Fehler">
                                    {(feed.hasError) ? (
                                        <div>
                                            <StaticInput value={dateTime(feed.lastError)}/>
                                            <Button color="danger" small={true}
                                                    onClick={() => runInAction(() => uiState.showUpdateErrorFeedId = feed.id)}>
                                                Anzeigen
                                            </Button>
                                            <Button color="warning" small={true} onClick={() => this.reactivate()}>
                                                Reaktivieren
                                            </Button>
                                        </div>
                                    ) : (<StaticInput value="Keine Fehler!"/>)}
                                </FormField>
                                <FormField label="Pub. Datum">
                                    <StaticInput value={dateTime(feed.pubDate)}/>
                                </FormField>
                                <FormField label="Löschen">
                                    <Button color="danger" small={true} onClick={() => this.deleteFeed()}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </Button>
                                </FormField>
                            </div>
                        </div>) : ''}
                    {(this.feedExpanded) ? (
                        <div className="columns">
                            <div className="column">
                                <Blacklist feed={feed}/>
                            </div>
                        </div>
                    ) : ''}
                </div>
            </div>
        );
    }
}

export default Feed;
