import * as React from 'react';
import { throttle, DebouncedFunc } from 'lodash';
import AddBlacklist from '../../AddBlacklist';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../../stores/RootStore';
import './ItemList.scss';
import Item from './item/Item';
import { action, makeObservable } from 'mobx';

@inject('rootStore')
@observer
class ItemList extends React.Component<RootStoreProp, {}> {

    onScroll: DebouncedFunc<() => void>;

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            closeNewBlacklistItem: action
        });

        this.onScroll = throttle(() => this.handleScroll(), 2000, {leading: false});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    handleScroll() {
        let documentHeight = document.body.scrollHeight;
        let innerHeight = window.innerHeight;
        let scrollPosition = window.scrollY;

        let viewPortBottomLine = scrollPosition + innerHeight;
        if (viewPortBottomLine > documentHeight - 2 * innerHeight) {
            this.props.rootStore!.itemStore.loadMore();
        }
    }

    addNewBlacklistItem(feedId: number, expression: string) {
        this.props.rootStore!.feedStore.addNewBlacklistEntry(feedId, expression);
    }

    closeNewBlacklistItem() {
        this.props.rootStore!.uiStateStore.showAddBlacklistEntry = false;
    }

    render() {
        let itemStore = this.props.rootStore!.itemStore;

        let items = itemStore.get();

        return (
            <div className="is-item-list">
                <AddBlacklist add={(feedId, expression) => this.addNewBlacklistItem(feedId, expression)}
                              close={() => this.closeNewBlacklistItem()}/>
                {
                    (items == null || items.length === 0) ?
                        (<div><p>Keine Eintr&auml;ge</p></div>) :
                        (items.map((item) => {
                            return (<Item key={item.id} item={item}/>);
                        }))
                }
                <div style={{height: '100vh'}}/>
            </div>);
    }
}

export default ItemList;
