import * as React from 'react';
import './Sidebar.scss';
import { RootStoreProp } from '../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import { CategoryAndFeeds } from '../../../stores/model/CategoryAndFeeds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Badge } from '../../bulma';
import Category from './Category';

@inject('rootStore')
@observer
class Sidebar extends React.Component<RootStoreProp, {}> {

    searchFieldError = false;

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            searchFieldError: observable,
            categoriesItemCount: computed,
            onSelectAll: action
        });
    }

    get categoriesItemCount(): number {
        return this.props.rootStore!.categoriesAndFeedsStore.get()
            .map(c => c.count).reduce((first, second) => first + second, 0);
    }

    onSelectAll(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        const uiState = this.props.rootStore!.uiStateStore;
        uiState.selectedCategoryId = 0;
        uiState.selectedFeedId = null;
        uiState.after = null;
        window.scrollTo(0, 0);
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;
        const cAndF = this.props.rootStore!.categoriesAndFeedsStore;

        return (
            <div className="column is-4-tablet is-3-desktop is-2-widescreen">
                <nav className="menu is-sidebar is-fullheight">
                    <ul className="menu-list">
                        <li>
                            <a className={classNames({'is-active': uiState.selectedCategoryId === 0})} href="#/"
                               onClick={(e) => this.onSelectAll(e)}>
                                <FontAwesomeIcon icon={faAlignJustify} fixedWidth={true}/> Alle Eintr&auml;ge
                                {(this.categoriesItemCount > 0) ? (<Badge value={this.categoriesItemCount}/>) : ''}
                            </a>
                        </li>
                        {
                            cAndF.get().map((category: CategoryAndFeeds) => {
                                return (
                                    <Category key={category.id} category={category}/>
                                );
                            })
                        }
                    </ul>
                    <div style={{height: '10vh'}}/>
                </nav>
            </div>
        );
    }
}

export default Sidebar;
