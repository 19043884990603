import * as React from 'react';
import DateTime from '../util/DateTime';
import { autorun, IReactionDisposer, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as ModelFeed from '../../stores/model/Feed';
import { RootStoreProp } from '../../stores/RootStore';
import { Modal } from '../bulma';

@inject('rootStore')
@observer
class UpdateError extends React.Component<RootStoreProp, {}> {
    updateErrorDisposer: IReactionDisposer | null = null;

    componentDidMount() {
        this.updateErrorDisposer = autorun(() => {
            let updateErrorFeedId = this.props.rootStore!.uiStateStore.showUpdateErrorFeedId;
            if (updateErrorFeedId) {
                this.props.rootStore!.feedStore.fetchUpdateErrors(
                    this.props.rootStore!.feedStore.get().find(f => f.id === updateErrorFeedId)!);
            }
        });
    }

    componentWillUnmount() {
        if (this.updateErrorDisposer != null) {
            this.updateErrorDisposer();
        }
    }

    render() {
        let uiState = this.props.rootStore!.uiStateStore;
        let feed = this.props.rootStore!.feedStore.get().find(f => f.id === uiState.showUpdateErrorFeedId);
        let feedErrors = feed ?
            this.props.rootStore!.feedStore.getUpdateErrors(feed) : Array<ModelFeed.UpdateError>();

        return (
            <Modal label="Update Fehler" show={uiState.showUpdateErrorFeedId !== null}
                   close={() => runInAction(() => uiState.showUpdateErrorFeedId = null)} closeText="Schliessen">
                {
                    feedErrors.map((error) => {
                        return (
                            <div key={error.date}>
                                <strong><DateTime dateTime={error.date}/></strong><br/>
                                <div>
                                    <code>{error.error}</code>
                                </div>
                            </div>);
                    })
                }
            </Modal>
        );
    }

}

export default UpdateError;