import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';

@observer
class Input extends React.Component <InputProps, {}> {

    isInvalid = false;

    constructor(props: InputProps) {
        super(props);

        makeObservable(this, {
            isInvalid: observable,
            inputChange: action.bound,
            validationSucessful: action,
            validationFailed: action
        });
    }

    static isValidRegex(expression: string) {
        try {
            RegExp(expression);
            return true;
        } catch (error) {
            return false;
        }
    }

    inputChange(event: React.ChangeEvent<HTMLInputElement>) {
        let newValue = event.target.value;
        this.validationSucessful();

        if (this.props.validations && this.props.validations !== '') {
            if (this.props.validations.indexOf('regex') > -1 && !Input.isValidRegex(newValue)) {
                this.validationFailed();
            }
        }

        this.props.onChange(newValue);
    }

    validationSucessful() {
        this.isInvalid = false;
        if (this.props.validationResult) {
            this.props.validationResult(true);
        }
    }

    validationFailed() {
        this.isInvalid = true;
        if (this.props.validationResult) {
            this.props.validationResult(false);
        }
    }

    render() {
        let inputType = this.props.type ? this.props.type : 'text';
        return (
            <input
                type={inputType}
                className={classNames(
                    'input',
                    {'is-danger': this.isInvalid},
                    {'is-small': this.props.small},
                    {'is-rounded': this.props.rounded}
                )}
                value={this.props.value}
                onChange={this.inputChange}
                placeholder={this.props.placeholder}
            />
        );
    }
}

interface InputProps {
    onChange: (newValue: string) => void,
    type?: string,
    value?: string,
    placeholder?: string,
    small?: boolean,
    rounded?: boolean,
    validations?: string,
    validationResult?: (result: boolean) => void,
}

export default Input;
