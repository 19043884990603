import * as React from 'react';

const Page404 = () => (
    <section className="hero is-fullheight is-light">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-3">
                        <h1 className="title">404</h1>
                        <p className="subtitle">Oops! You're lost.</p>
                        <p>The page you are looking for was not found.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>);

export default Page404;
