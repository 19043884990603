import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Checkbox, FormField, Select } from '../bulma';
import SimpleCard from '../bulma/SimpleCard';

@inject('rootStore')
@observer
class ReaderSettings extends React.Component<RootStoreProp, {}> {

    render() {
        let uiState = this.props.rootStore!.uiStateStore;
        return (
            <SimpleCard title="Reader Einstellungen">
                <FormField label="Gelesen">
                    <Checkbox text="Beim Scrollen als gelesen markieren?" value={uiState.markReadOnScroll}
                              onChange={(newValue) => uiState.markReadOnScroll = newValue}/>
                </FormField>
                <FormField label="Safe For Work">
                    <Checkbox value={uiState.sfw} onChange={(newValue) => uiState.sfw = newValue}/>
                </FormField>
                <FormField label="Sortierung">
                    <Select value={uiState.itemOrderMode} onChange={(newValue) => uiState.itemOrderMode = newValue}>
                        <option value="newest">Nach Datum</option>
                        <option value="title">Nach Name</option>
                        <option value="random">Zufällig</option>
                    </Select>
                </FormField>
                <FormField label="Reihenfolge">
                    <Select value={uiState.itemOrderModeDir}
                            onChange={(newValue) => uiState.itemOrderModeDir = newValue}>
                        <option value="asc">Aufsteigend</option>
                        <option value="desc">Absteigend</option>
                    </Select>
                </FormField>
                <FormField label="Bilder an Bildschirmhöhe anpassen">
                    <Checkbox value={uiState.fitImagesToScreen}
                              onChange={(newValue) => uiState.fitImagesToScreen = newValue}/>
                </FormField>
            </SimpleCard>
        );
    }
}

export default ReaderSettings;