import * as React from 'react';
import AddFeedDialog from '../reader/AddFeedDialog';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../bulma';

@inject('rootStore')
@observer
class AddFeed extends React.Component<RootStoreProp, {}> {

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            showAddFeedModal: action
        });
    }

    showAddFeedModal() {
        this.props.rootStore!.uiStateStore.showAddFeed = true;
    }

    render() {
        return (
            <div className="navbar-item">
                <Button color="info" onClick={() => this.showAddFeedModal()} title="Add Feed">
                    <FontAwesomeIcon icon={faPlusSquare}/>
                </Button>
                <AddFeedDialog/>
            </div>
        );
    }
}

export default AddFeed;
