import * as React from 'react';
import { isUndefined, throttle } from 'lodash';
import classNames from 'classnames';
import { RootStoreProp } from '../../../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import UiStateStore from '../../../../../stores/UiStateStore';
import * as ItemModel from '../../../../../stores/model/Item';
import ItemStore from '../../../../../stores/ItemStore';
import { action, makeObservable } from 'mobx';
import './Item.scss';
import Actions from './Actions';
import Header from './Header';
import Categories from './Categories';

interface ItemProps {
    item: ItemModel.Item;
}

@inject('rootStore')
@observer
class Item extends React.Component<ItemProps & RootStoreProp, {}> {

    onScroll: () => void;
    uiState: UiStateStore;
    itemStore: ItemStore;
    itemRef: React.RefObject<HTMLDivElement>;

    constructor(props: ItemProps & RootStoreProp) {
        super(props);

        makeObservable(this, {
            showAddBlacklistRule: action
        });

        this.itemRef = React.createRef();

        this.uiState = this.props.rootStore!.uiStateStore;
        this.itemStore = this.props.rootStore!.itemStore;

        this.onScroll = throttle(() => this.handleScroll(), 200, {leading: false});
    }

    componentDidMount() {
        if (this.props.item.readTime === null && this.uiState.markReadOnScroll) {
            window.addEventListener('scroll', this.onScroll);
        }
    }

    componentWillUnmount() {
        this.removeScrollHandler();
    }

    removeScrollHandler() {
        if (this.onScroll !== null) {
            window.removeEventListener('scroll', this.onScroll);
        }
    }

    toggleItemCollapse() {
        this.itemStore.toggleItemCollapse(this.props.item);
    }

    showAddBlacklistRule() {
        this.uiState.showAddBlacklistEntryFeedId = this.props.item.feedId;
        this.uiState.showAddBlacklistEntryExpression = this.props.item.title || '';
        this.uiState.showAddBlacklistEntry = true;
    }

    handleScroll() {
        // In case the event got handled too late, we don't do a thing!
        if (!this.onScroll) {
            return;
        }

        try {
            let thisElement = this.itemRef.current;
            if (thisElement !== null) {
                let elementOffset = thisElement.offsetTop;
                let windowScroll = window.scrollY;

                if (elementOffset < windowScroll - 100) {
                    this.itemStore.markItemRead(this.props.item);
                    this.removeScrollHandler();
                }
            }
        } catch {
            // do nothing!
        }
    }

    render() {
        let uiState = this.props.rootStore!.uiStateStore;
        let item = this.props.item;
        let itemCollapsed = uiState.itemCollapsed.get(item.id.toString());

        let collapsed = uiState.itemViewMode === 'collapsed';
        if (!isUndefined(itemCollapsed)) {
            collapsed = itemCollapsed;
        }

        return (
            <div
                key={item.id}
                ref={this.itemRef}
                onScroll={() => this.onScroll()}
                className={classNames('card', 'is-rounded', 'item', {'is-unread': item.readTime === null})}>
                <Header item={item} toggleContent={() => this.toggleItemCollapse()}/>
                <div className={classNames('card-content', {'is-hidden': collapsed})}>
                    <div className="is-clearfix" dangerouslySetInnerHTML={{__html: item.content}}/>
                </div>
                <div className={classNames('card-content', {'is-hidden': !collapsed})}>
                    <div className="is-clearfix">
                        <div className="columns is-mobile">
                            <div
                                className={classNames(
                                    'column', 'is-4-mobile', 'is-2-desktop', {'is-hidden': !item.excerptImage})}
                                dangerouslySetInnerHTML={{__html: item.excerptImage}}/>
                            <div className="column">
                                {item.excerpt}
                            </div>
                        </div>
                    </div>
                </div>
                <Categories item={item} hidden={collapsed}/>
                <Actions item={item} hidden={collapsed}/>
            </div>);
    }
}

export default (Item);
