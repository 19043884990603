import * as React from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { RouteComponentProps } from 'react-router';
import { Input } from '../bulma';

@inject('rootStore')
@observer
class Search extends React.Component<RootStoreProp, {}> {
    searchFieldError = false;
    private readonly onSearchInput: DebouncedFunc<(searchTerm: string) => void>;

    constructor(props: RootStoreProp & RouteComponentProps<{}>) {
        super(props);

        makeObservable(this, {
            searchFieldError: observable,
            onSearch: action
        });

        this.onSearchInput = debounce(this.onSearch, 400);
    }

    onSearch(searchTerm: string) {
        this.props.rootStore!.uiStateStore.search = searchTerm;
    }

    render() {

        return (
            <div className="navbar-item">
                <Input placeholder="Suche..." rounded={true} small={true} validations="regex"
                       onChange={(newValue) => this.onSearchInput(newValue)}/>
            </div>
        );
    }
}

export default Search;
