import * as React from 'react';
import './Sidebar.scss';
import { RootStoreProp } from '../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { FeedAndCount } from '../../../stores/model/FeedAndCount';
import classNames from 'classnames';
import Feed from './Feed';
import { Badge } from '../../bulma';
import { CategoryAndFeeds } from '../../../stores/model/CategoryAndFeeds';

@inject('rootStore')
@observer
class Category extends React.Component<{ category: CategoryAndFeeds } & RootStoreProp, {}> {

    constructor(props: { category: CategoryAndFeeds } & RootStoreProp) {
        super(props);

        makeObservable(this, {
            onSelectCategory: action
        });
    }

    onSelectCategory(event: React.MouseEvent<HTMLAnchorElement>, categoryId: number | null) {
        event.preventDefault();
        const uiState = this.props.rootStore!.uiStateStore;
        if (categoryId !== null) {
            uiState.selectedCategoryId = categoryId;
            uiState.selectedFeedId = null;
            uiState.after = null;
            window.scrollTo(0, 0);
        }
    }

    // noinspection JSMethodCanBeStatic
    handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        if (e.currentTarget.parentElement) {
            e.currentTarget.parentElement.classList.toggle('open');
        }
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;
        let category = this.props.category;

        return (
            <li className="has-dropdown is-hoverable">
                <a className={classNames(
                    {'is-active': uiState.selectedCategoryId === category.id})}
                   href="#/"
                   onClick={(e) => {
                       this.onSelectCategory(e, category.id);
                       this.handleClick(e);
                   }}>
                    {category.name}
                    {(category.count > 0) ? (<Badge value={category.count}/>) : ''}
                </a>
                <ul className="is-dropdown is-marginless">
                    {category.feeds.map((feed: FeedAndCount) =>
                        <Feed key={feed.id} feedProvider={() => feed}/>
                    )}
                </ul>
            </li>
        );
    }
}

export default Category;
