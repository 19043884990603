import * as url from 'url';
import * as qs from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import { toast } from 'react-toastify';
import * as H from 'history';
import { AUTH_STORE_INSTANCE } from './stores/AuthStore';
import User from './stores/model/User';

const REEDER_TOKEN = 'reeeder-token';
const REEDER_USER = 'reeeder-user';

function removeStoredValue(what: string) {
    localStorage.removeItem(what);
}

function setStoredValue(what: string, value: string | User | null) {
    localStorage.setItem(what, JSON.stringify(value));
}

export function getStoredToken(): string | null {
    return JSON.parse(localStorage.getItem(REEDER_TOKEN)!);
}

export function getStoredUser(): User | null {
    return JSON.parse(localStorage.getItem(REEDER_USER)!);
}

interface OAuthConfig {
    url: string;
    clientId: string;
    redirectUri: string;
    authorizationUrl: string;
    scope: string;
    width: number;
    height: number;
}

export function logout() {
    removeStoredValue(REEDER_TOKEN);
    removeStoredValue(REEDER_USER);
}

// Sign in with Microsoft
export function microsoftLogin(history: H.History) {
    const microsoft = {
        url: `${window.location.protocol}//${window.location.host}/api/auth/microsoft`,
        clientId: '0b6ee996-b5f9-4da1-9ced-da6a072863f7',
        redirectUri: `${window.location.protocol}//${window.location.host}/auth/redirect`,
        authorizationUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        scope: 'user.read user.readbasic.all',
        width: 660,
        height: 500
    };

    return oauth2(microsoft, history)
        .then(openPopup)
        .then(pollPopup)
        .then(exchangeCodeForToken)
        .then(signIn)
        .then(closePopup)
        .then(redirectAfterLogin);
}

// Sign in with Google
export function googleLogin(history: H.History) {
    const google = {
        url: `${window.location.protocol}//${window.location.host}/api/auth/google`,
        clientId: '365847092865-1kp00bbceq3drgnallrrrq14u2kv5df5.apps.googleusercontent.com',
        redirectUri: `${window.location.protocol}//${window.location.host}/auth/redirect`,
        authorizationUrl: 'https://accounts.google.com/o/oauth2/auth',
        scope: 'openid profile email',
        width: 452,
        height: 633
    };

    return oauth2(google, history)
        .then(openPopup)
        .then(pollPopup)
        .then(exchangeCodeForToken)
        .then(signIn)
        .then(closePopup)
        .then(redirectAfterLogin);
}

// Sign in with Github
export function githubLogin(history: H.History) {
    const github = {
        url: `${window.location.protocol}//${window.location.host}/api/auth/github`,
        clientId: 'e4b71c3d01f6cbc92c2a',
        redirectUri: `${window.location.protocol}//${window.location.host}/auth/redirect`,
        authorizationUrl: 'https://github.com/login/oauth/authorize',
        scope: 'user:email',
        width: 452,
        height: 633
    };

    return oauth2(github, history)
        .then(openPopup)
        .then(pollPopup)
        .then(exchangeCodeForToken)
        .then(signIn)
        .then(closePopup)
        .then(redirectAfterLogin);
}

function oauth2(config: OAuthConfig, history: H.History): Promise<OpenPopupProps> {
    return new Promise((resolve, reject) => {
        const params = {
            client_id: config.clientId,
            redirect_uri: config.redirectUri,
            scope: config.scope,
            display: 'popup',
            response_type: 'code'
        };
        const authUrl = config.authorizationUrl + '?' + qs.stringify(params);
        resolve({openUrl: authUrl, config: config, history: history});
    });
}

interface OpenPopupProps {
    openUrl: string;
    config: OAuthConfig;
    history: H.History;
}

function openPopup({openUrl, config, history}: OpenPopupProps): Promise<PollPopupProps> {
    return new Promise((resolve, reject) => {
        const width = config.width || 500;
        const height = config.height || 500;
        const options = {
            width: width,
            height: height,
            top: window.screenY + ((window.outerHeight - height) / 2.5),
            left: window.screenX + ((window.outerWidth - width) / 2)
        };
        const popup = window.open(openUrl, '_blank', qs.stringify(options, ','));

        if (openUrl === 'about:blank' && popup !== null) {
            popup.document.body.innerHTML = 'Loading...';
        }

        resolve({window: popup!, config: config, history: history, requestToken: null});
    });
}

interface PollPopupProps {
    window: Window;
    config: OAuthConfig;
    history: H.History;
    requestToken: ParsedUrlQueryInput | null;
}

function pollPopup({window, config, history, requestToken}: PollPopupProps): Promise<ExchangeCodeForTokenProps> {
    return new Promise((resolve, reject) => {
        const redirectUri = url.parse(config.redirectUri);
        const redirectUriPath = redirectUri.host! + redirectUri.pathname!;

        if (requestToken) {
            window.location.replace(config.authorizationUrl + '?' + qs.stringify(requestToken));
        }

        const polling = setInterval(
            () => {
                if (!window || window.closed) {
                    clearInterval(polling);
                }
                try {
                    const popupUrlPath = window.location.host + window.location.pathname;
                    if (popupUrlPath === redirectUriPath) {
                        if (window.location.search || window.location.hash) {
                            const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
                            const hash = qs.parse(window.location.hash.substring(1).replace(/[/$]/, ''));
                            const params = Object.assign({}, query, hash);

                            if (params.error) {
                                toast.error(params.error.toString());
                            } else {
                                resolve({
                                    oauthData: params,
                                    config: config,
                                    history: history,
                                    window: window,
                                    interval: polling
                                });
                            }
                        } else {
                            toast.error('OAuth redirect has occurred but no query or hash parameters were found.');
                        }
                    }
                } catch (error) {
                    // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
                    // A hack to get around same-origin security policy errors in Internet Explorer.
                }
            },
            500);
    });
}

interface ExchangeCodeForTokenProps {
    oauthData: {};
    config: OAuthConfig;
    history: H.History;
    window: Window;
    interval: NodeJS.Timeout;
}

function exchangeCodeForToken({
                                  oauthData,
                                  config,
                                  history,
                                  window,
                                  interval
                              }: ExchangeCodeForTokenProps): Promise<SignInProps> {
    return new Promise((resolve, reject) => {
        const data = Object.assign({}, oauthData, config);

        return fetch(config.url, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'same-origin', // By default, fetch won't send any cookies to the server
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json().then((json) => {
                    resolve({
                        token: json.token,
                        user: json.user,
                        history: history,
                        window: window,
                        interval: interval
                    });
                });
            } else {
                return response.json().then((json) => {
                    toast.error(json);
                    closePopup({window: window, history: history, interval: interval});
                });
            }
        }).catch((error) => {
            toast.error(`OAuth failed ${error}`);
        });
    });
}

interface SignInProps {
    token: string;
    user: User;
    history: H.History;
    window: Window;
    interval: NodeJS.Timeout;
}

function signIn({token, user, history, window, interval}: SignInProps): Promise<ClosePopupProps> {
    return new Promise((resolve, reject) => {
        toast.info('Login successful');
        setStoredValue(REEDER_TOKEN, token);
        setStoredValue(REEDER_USER, user);
        AUTH_STORE_INSTANCE.setToken(token);
        AUTH_STORE_INSTANCE.setUser(user);
        resolve({window: window, history: history, interval: interval});
    });

}

interface ClosePopupProps {
    window: Window;
    history: H.History;
    interval: NodeJS.Timeout;
}

function closePopup({window, history, interval}: ClosePopupProps): Promise<RedirectAfterLoginProps> {
    return new Promise((resolve, reject) => {
        clearInterval(interval);
        window.close();
        resolve({history: history});
    });
}

interface RedirectAfterLoginProps {
    history: H.History;
}

function redirectAfterLogin({history}: RedirectAfterLoginProps) {
    return new Promise<void>((resolve, reject) => {
        history.push('/reader');
        resolve();
    });
}
