import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../bulma';

@inject('rootStore')
@observer
class Reload extends React.Component<RootStoreProp, {}> {
    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            reloadEverything: action
        });
    }

    reloadEverything() {
        window.scrollTo(0, 0);
        this.props.rootStore!.categoryStore.loadAll();
        this.props.rootStore!.categoriesAndFeedsStore.reload();
        this.props.rootStore!.feedStore.loadAll();
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;
        let updating = uiState.categoriesAndFeedsUpdating || uiState.categoriesUpdating || uiState.feedsUpdating || uiState.itemsUpdating;

        return (
            <div className="navbar-item">
                <Button color="info" small={true} loading={updating}
                        onClick={() => this.reloadEverything()}>
                    <FontAwesomeIcon icon={faSync}/>
                </Button>
            </div>
        );
    }
}

export default Reload;
