import * as React from 'react';
import { action } from 'mobx';

interface CheckboxProps {
    id?: string;
    text?: string;
    value: boolean | undefined;
    onChange: (newValue: boolean) => void;
}

class Checkbox extends React.Component <CheckboxProps> {

    @action.bound
    checkboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(event.target.checked);
    }

    render() {
        return (
            <label className="checkbox">
                <input type="checkbox" id={this.props.id}
                       className="checkbox"
                       checked={this.props.value}
                       onChange={this.checkboxChange}/>
                &nbsp;{this.props.text ? this.props.text : ''}
            </label>
        );
    }
}

export default Checkbox;