import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconText: React.FunctionComponent<{ icon: IconProp, text: string }> = (props) => (
    <div>
        <span className="icon">
            <FontAwesomeIcon icon={props.icon} size={'xs'}/>
        </span>
        <span>{props.text}</span>
    </div>
);

export default IconText;