import * as React from 'react';

const SimpleCard: React.FunctionComponent<{ title: string }> = (props) => (
    <div className="card">
        <div className="card-header is-light">
            <div className="card-header-title">{props.title}</div>
        </div>
        <div className="card-content">
            {props.children}
        </div>
    </div>
);

export default SimpleCard;