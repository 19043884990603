import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../stores/RootStore';
import { action, makeObservable } from 'mobx';
import { faAsterisk, faBookmark, faHeart, faList } from '@fortawesome/free-solid-svg-icons';
import { IconText } from '../../bulma';

@inject('rootStore')
@observer
class ViewMode extends React.Component<RootStoreProp, {}> {

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            displayFilter: action
        });
    }

    displayFilter(filter: string) {
        window.scrollTo(0, 0);
        this.props.rootStore!.uiStateStore.viewMode = filter;
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link" href="#/">
                    {uiState.viewMode === 'all' ? (
                        <IconText icon={faList} text={'Alle'}/>) : ''}
                    {uiState.viewMode === 'unread' ? (
                        <IconText icon={faAsterisk} text={'Nur ungelesene'}/>) : ''}
                    {uiState.viewMode === 'readLater' ? (
                        <IconText icon={faBookmark} text={'Read Later'}/>) : ''}
                    {uiState.viewMode === 'saved' ? (
                        <IconText icon={faHeart} text={'Gespeicherte'}/>) : ''}
                </a>
                <div className="navbar-dropdown">
                    <a className="navbar-item" href="#/" onClick={() => this.displayFilter('all')}>
                        <IconText icon={faList} text={'Alle'}/>
                    </a>
                    <a className="navbar-item" href="#/" onClick={() => this.displayFilter('unread')}>
                        <IconText icon={faAsterisk} text={'Nur ungelesene'}/>
                    </a>
                    <a className="navbar-item" href="#/" onClick={() => this.displayFilter('readLater')}>
                        <IconText icon={faBookmark} text={'Read Later'}/>
                    </a>
                    <a className="navbar-item" href="#/" onClick={() => this.displayFilter('saved')}>
                        <IconText icon={faHeart} text={'Gespeicherte'}/>
                    </a>
                </div>
            </div>
        );
    }
}

export default ViewMode;
