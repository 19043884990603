import * as React from 'react';
import DateTime from '../../../../util/DateTime';
import { RootStoreProp } from '../../../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import * as ItemModel from '../../../../../stores/model/Item';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import './Item.scss';

@inject('rootStore')
@observer
class Header extends React.Component<{ item: ItemModel.Item, toggleContent: () => void } & RootStoreProp, {}> {

    constructor(props: { item: ItemModel.Item, toggleContent: () => void } & RootStoreProp) {
        super(props);

        makeObservable(this, {
            showAddBlacklistRule: action
        });
    }

    showAddBlacklistRule() {
        let uiState = this.props.rootStore!.uiStateStore;
        uiState.showAddBlacklistEntryFeedId = this.props.item.feedId;
        uiState.showAddBlacklistEntryExpression = this.props.item.title || '';
        uiState.showAddBlacklistEntry = true;
    }

    render() {
        let item = this.props.item;
        let feed = this.props.rootStore!.feedStore.getById(item.feedId);

        return (
            <div className="card-header" onClick={() => this.props.toggleContent()}>
                <div className="card-header-title">
                    <a href={(item.uid !== null && item.uid.indexOf('http') >= 0) ? item.uid : item.url}
                       target="new">
                        {item.title}
                    </a>
                </div>
                <div className="card-header-right">
                    <small className="is-small has-text-grey-light">
                        <span>{feed ? feed.name : ''}</span>
                    </small>
                    <small className={classNames('is-small', 'has-text-grey', {'is-hidden': !item.readingTime})}>
                        <span>{item.readingTime} Minute(n)</span>
                    </small>
                    <small className="is-small has-text-grey-light">
                        <span onClick={() => this.showAddBlacklistRule()}><FontAwesomeIcon icon={faBan}/></span>
                    </small>
                    <small className="is-small has-text-grey">
                        <DateTime dateTime={item.date} humanize={true}/>
                    </small>
                </div>
            </div>);
    }
}

export default Header;
