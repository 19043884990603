import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { FormField, SimpleCard, StaticInput } from '../bulma';

@inject('rootStore')
@observer
class Account extends React.Component<RootStoreProp, {}> {
    render() {
        let authStore = this.props.rootStore!.authStore;

        return (
            <SimpleCard title="Account">
                <FormField label="Name">
                    <StaticInput value={authStore.getUser()!.name}/>
                </FormField>
                <FormField label="E-Mail">
                    <StaticInput value={authStore.getUser()!.email}/>
                </FormField>
            </SimpleCard>);
    }
}

export default Account;