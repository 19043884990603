import * as React from 'react';
import { isUndefined } from 'lodash';
import BlacklistEntry from './BlacklistEntry';
import { RootStoreProp } from '../../stores/RootStore';
import Feed from '../../stores/model/Feed';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../bulma';

@inject('rootStore')
@observer
class Blacklist extends React.Component<RootStoreProp & { feed: Feed }, {}> {

    componentDidMount() {
        this.props.rootStore!.feedStore.fetchBlacklist(this.props.feed);
    }

    addNewEntry() {
        this.props.rootStore!.feedStore.addBlacklistEntry(this.props.feed, {
            field: 'TITLE',
            expression: ''
        });
    }

    markReadByBlacklist() {
        this.props.rootStore!.feedStore.markReadByBlacklist(this.props.feed);
    }

    render() {
        let blacklist = this.props.rootStore!.feedStore.getBlacklist(this.props.feed);
        if (isUndefined(blacklist)) {
            blacklist = observable.array([]);
        }

        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title">
                        Blacklist
                    </div>
                    <div className="card-header-right">
                        <span onClick={() => this.markReadByBlacklist()}
                              title="Alle ungelesenen Einträge die in der Blacklist sind als gelesen markieren">
                            <FontAwesomeIcon icon={faPlayCircle}/>
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <table className="table is-fullwidth">
                        {(blacklist.length !== 0) ? (
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Expression</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                        ) : (<thead/>)}
                        <tbody style={{maxHeight: '20px', overflow: 'auto'}}>
                        {
                            blacklist.map((blacklistEntry) => {
                                return (
                                    <BlacklistEntry key={blacklistEntry.id} feed={this.props.feed}
                                                    entry={blacklistEntry}/>
                                );
                            })
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>
                                <Button onClick={() => this.addNewEntry()} small={true}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </Button>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>);
    }
}

export default Blacklist;
