import RootStore from './RootStore';
import { getStoredToken, getStoredUser } from '../oauth';
import User from './model/User';

export let AUTH_STORE_INSTANCE: AuthStore;

export default class AuthStore {
    rootStore: RootStore;
    token: string | null = null;
    user: User | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        AUTH_STORE_INSTANCE = this;

        // try load from localStorage
        this.loadToken();
        this.loadUser();
    }

    setToken(token: string) {
        this.token = token;
    }

    getToken(): string | null {
        this.loadToken();
        return this.token;
    }

    isAuthenticated(): boolean {
        return this.getToken() != null;
    }

    setUser(user: User) {
        this.user = user;
    }

    getUser(): User | null {
        this.loadUser();
        return this.user;
    }

    private loadToken() {
        if (this.token === null && getStoredToken() !== null) {
            this.token = getStoredToken();
        }
    }

    private loadUser() {
        if (this.user === null && getStoredUser() !== null) {
            this.user = User.fromJS(getStoredUser()!);
        }
    }
}