import * as React from 'react';
import classNames from 'classnames';

const FormField: React.FunctionComponent<{ label: string, hidden?: boolean }> = (props) => (
    <div className={classNames('field', 'is-horizontal', {'is-hidden': props.hidden})}>
        <div className="field-label is-normal">
            <label className="label">{props.label}</label>
        </div>
        <div className="field-body">
            {props.children}
        </div>
    </div>
);

export default FormField;