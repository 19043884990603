import * as React from 'react';
import './Tooltip.scss';

const Tooltip: React.FunctionComponent = (props) => (
    <div className="tooltip">
        {props.children}
    </div>
);

const TooltipText: React.FunctionComponent = (props) => (
    <div className="tooltipText">
        {props.children}
    </div>
);

export {
    Tooltip,
    TooltipText
};