import * as React from 'react';
import Account from './Account';
import Categories from './Categories';
import ReaderSettings from './ReaderSettings';
import Feeds from './Feeds';

const Settings = () => (
    <div className="columns">
        <div className="column">
            <section className="section">
                <div className="columns is-centered">
                    <div className="column is-7-widescreen is-8-desktop">
                        <Account/>
                        <br/>
                        <ReaderSettings/>
                        <br/>
                        <Categories/>
                        <br/>
                        <Feeds/>
                    </div>
                </div>
            </section>
        </div>
    </div>);

export default Settings;