import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { RootStoreProp } from '../../stores/RootStore';
import { FormField, Input, Modal } from '../bulma';

interface AddBlacklistProps {
    add: (feedId: number, expression: string) => void;
    close: Function;
}

@inject('rootStore')
@observer
class AddBlacklist extends React.Component<AddBlacklistProps & RootStoreProp, {}> {

    expressionHasError: boolean = false;

    constructor(props: AddBlacklistProps & RootStoreProp) {
        super(props);

        makeObservable(this, {
            expressionHasError: observable
        });
    }

    render() {
        let uiState = this.props.rootStore!.uiStateStore;

        return (
            <Modal label="Block Regel hinzufügen" show={uiState.showAddBlacklistEntry} close={() => this.props.close()}
                   action={() => this.props.add(
                       uiState.showAddBlacklistEntryFeedId,
                       uiState.showAddBlacklistEntryExpression
                   )} actionText="Hinzufügen"
                   actionDisabled={this.expressionHasError}>
                <FormField label={'Ausdruck'}>
                    <Input value={uiState.showAddBlacklistEntryExpression}
                           onChange={(newValue) => uiState.showAddBlacklistEntryExpression = newValue}
                           placeholder="Regulärer Ausdruck"
                           validations="regex"
                           validationResult={(result) => this.expressionHasError = !result}
                    />
                </FormField>
            </Modal>
        );
    }
}

export default AddBlacklist;
