import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../stores/RootStore';
import Toolbar from './toolbar/Toolbar';
import ItemList from './layout/list/ItemList';
import Sidebar from './sidebar/Sidebar';
import ItemColumns from './layout/columns/ItemColumns';


@inject('rootStore')
@observer
class Reader extends React.Component<RootStoreProp, {}> {

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
                <div className="columns">
                    <Sidebar/>
                    <div className="column">
                        <Toolbar/>
                        {uiState.itemLayout === 'list' ? (<ItemList/>) : (<ItemColumns/>)}
                    </div>
                </div>
        );
    }
}

export default Reader;