import * as React from 'react';
import { logout } from '../../oauth';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, withRouter } from 'react-router';
import { IconText } from '../bulma';
import { NavLink } from 'react-router-dom';

@inject('rootStore')
@observer
class UserMenu extends React.Component<RootStoreProp & RouteComponentProps<{}>, {}> {

    handleLogout(event: Event) {
        event.preventDefault();
        logout();
        this.props.history.push('/login');
    }

    render() {
        const authStore = this.props.rootStore!.authStore;

        return (
            <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">
                    <img src={authStore.getUser()!.picture} className="image is-rounded"
                         alt=""/>
                </div>
                <div className="navbar-dropdown is-right">
                    <NavLink to="/settings" className="navbar-item">
                        <IconText icon={faCog} text="Einstellungen"/>
                    </NavLink>
                    <div className="navbar-item">
                        <div onClick={(e) => this.handleLogout(e.nativeEvent)}>
                            <IconText icon={faSignOutAlt} text="Abmelden"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserMenu);