import { action, autorun, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { CategoryAndFeeds } from './model/CategoryAndFeeds';
import { delay } from 'lodash';
import Item from './model/Item';
import BaseStore from './BaseStore';
import RootStore from "./RootStore";

export default class CategoriesAndFeedsStore extends BaseStore {
    categoriesAndFeeds: IObservableArray<CategoryAndFeeds> = observable.array();

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            categoriesAndFeeds: observable,
            load: action,
            updateUnreadCounter: action
        });
    }

    get() {
        return this.categoriesAndFeeds;
    }

    autoLoad(): void {
        autorun(() => {
            let uiState = this.rootStore.uiStateStore;

            this.load(uiState.viewMode, uiState.sfw, uiState.search);
        });

        const autoUpdateIntervalSec = 60;

        let autoReloadFunc = () => {
            this.reload();
            delay(autoReloadFunc, autoUpdateIntervalSec * 1000);
        };

        delay(autoReloadFunc, autoUpdateIntervalSec * 1000); // Start auto-update
    }

    reload() {
        let uiState = this.rootStore.uiStateStore;

        this.load(uiState.viewMode, uiState.sfw, uiState.search);
    }

    async load(viewMode: string, sfw: boolean | null, search: string | null) {
        let additionalParams = this.additionalParameters(null, sfw, search);

        this.rootStore.uiStateStore.categoriesAndFeedsUpdating = true;
        let categoriesAndFeeds: Array<CategoryAndFeeds> = await this.jsonGet(`api/feeds/and/categories/${viewMode}${additionalParams}`);
        runInAction(() => {
            this.categoriesAndFeeds.replace(categoriesAndFeeds.map(cf => CategoryAndFeeds.fromJS(cf)));
            this.rootStore.uiStateStore.categoriesAndFeedsUpdating = false;
        });
    }

    async markSelectionAsRead() {
        let selectedCategoryId = this.rootStore.uiStateStore.selectedCategoryId;
        let selectedFeedId = this.rootStore.uiStateStore.selectedFeedId;
        let objectType: string | null = null;
        let objectId: number | null = null;

        if (selectedFeedId) {
            objectType = 'feeds';
            objectId = selectedFeedId;
        } else if (selectedCategoryId) {
            objectType = 'categories';
            objectId = selectedCategoryId;
        }

        await this.httpPatch(`api/${objectType}/${objectId}/markRead`);
        this.reload();
    }

    updateUnreadCounter(item: Item) {
        this.categoriesAndFeeds.forEach(category => {
            let feed = category.feeds.find(f => f.id === item.feedId);
            if (feed) {
                let increment = item.readTime !== null ? -1 : 1;
                feed.count += increment;
                category.count += increment;
            }
        });
    }
}
