import * as React from 'react';
import classNames from 'classnames';
import { RootStoreProp } from '../../../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import * as ItemModel from '../../../../../stores/model/Item';
import { action, makeObservable } from 'mobx';
import './Item.scss';
import DateTime from '../../../../util/DateTime';

interface ItemProps {
    item: ItemModel.Item;
}

@inject('rootStore')
@observer
class Item extends React.Component<ItemProps & RootStoreProp, {}> {

    itemRef: HTMLDivElement | null = null;

    constructor(props: ItemProps & RootStoreProp) {
        super(props);

        makeObservable(this, {
            selectItem: action
        });
    }

    selectItem(event: React.MouseEvent<HTMLDivElement>, item: ItemModel.Item) {
        event.preventDefault();
        this.props.rootStore!.uiStateStore.itemSelected = item.id;
        this.props.rootStore!.itemStore.markItemRead(item);
        window.scrollTo(0, 0);
    }

    scrollToItem() {
        let itemListDivs = document.getElementsByClassName("is-list");
        if (itemListDivs.length === 1 && this.itemRef) {
            let itemListDiv = itemListDivs[0];
            itemListDiv.scrollTop = this.itemRef.offsetTop - 50;
        }
    }

    render() {
        let item = this.props.item;
        let feed = this.props.rootStore!.feedStore.getById(item.feedId);

        let isSelected = item.id === this.props.rootStore!.uiStateStore.itemSelected;
        if (isSelected) {
            this.scrollToItem();
        }

        return (
            <div
                ref={c => (this.itemRef = c)}
                key={item.id}
                onClick={(e) => this.selectItem(e, item)}
                className={classNames('card', 'is-item', {'is-unread': item.readTime === null}, {'is-item-selected': isSelected})}>
                <div className="card-content is-small-padding">
                    <strong className="has-text-info">{item.title}</strong><br/>
                    <small className="is-small has-text-grey-light">
                        <span>{feed ? feed.name : ''}</span>
                    </small>
                    <br/>
                    <small className="is-small has-text-grey">
                        <DateTime dateTime={item.date} humanize={true}/>
                    </small>
                </div>
            </div>);
    }
}

export default (Item);
