import { makeAutoObservable } from 'mobx';

export default class User {
    id: number;
    email: string;
    picture: string;
    name: string;
    google: string;
    live: string;
    github: string;

    static fromJS(object: User): User {
        return new User(
            object.id, object.email, object.picture, object.name,
            object.google, object.live, object.github
        );
    }

    constructor(id: number, email: string, picture: string, name: string,
                google: string, live: string, github: string) {
        makeAutoObservable(this);

        this.id = id;
        this.email = email;
        this.picture = picture;
        this.name = name;
        this.google = google;
        this.live = live;
        this.github = github;
    }
}
