import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import Reader from './reader/Reader';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../stores/RootStore';
import Header from './header/Header';
import Settings from './settings/Settings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HotKeys } from 'react-hotkeys';
import { action, makeObservable } from 'mobx';

@inject('rootStore')
@observer
class App extends React.Component<RootStoreProp & RouteComponentProps<{}>, {}> {

    // Keymap
    keyMap = {
        safeForWork: 'ctrl+shift+s',
        fitImagesToScreen: 'ctrl+shift+f'
    };

    keyHandlers = {
        'safeForWork': () => this.toggleSafeForWork(),
        'fitImagesToScreen': () => this.toggleFitImagesToScreen()
    };

    constructor(props: RootStoreProp & RouteComponentProps<{}>) {
        super(props);

        makeObservable(this, {
            toggleSafeForWork: action,
            toggleFitImagesToScreen: action
        });
    }

    toggleSafeForWork() {
        this.props.rootStore!.uiStateStore.sfw = !this.props.rootStore!.uiStateStore.sfw;
    }

    toggleFitImagesToScreen() {
        this.props.rootStore!.uiStateStore.fitImagesToScreen = !this.props.rootStore!.uiStateStore.fitImagesToScreen;
    }

    componentDidMount() {
        this.props.rootStore!.categoriesAndFeedsStore.autoLoad();
        this.props.rootStore!.categoryStore.loadAll();
        this.props.rootStore!.feedStore.loadAll();
        this.props.rootStore!.itemStore.autoLoad();
    }

    render() {
        if (this.props.rootStore!.authStore.isAuthenticated()) {
            return (
                <div className="has-navbar-fixed-top">
                    <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
                        <ToastContainer/>
                        <Header/>
                        <Switch>
                            <Route path="/reader" component={Reader}/>
                            <Route path="/settings" component={Settings}/>
                            <Redirect from="/" to="/reader"/>
                        </Switch>
                    </HotKeys>
                    {
                        this.props.rootStore!.uiStateStore.fitImagesToScreen ? <style dangerouslySetInnerHTML={{
                            __html: `
                                img.img-fluid {
                                    max-height: 65vh;
                                    width: auto;
                                }
                            `
                        }}/> : ''
                    }
                </div>
            );
        } else {
            return <Redirect to={{pathname: '/login'}}/>;
        }
    }
}

export default withRouter(App);
