import { action, IObservableArray, makeObservable, observable, runInAction, toJS } from 'mobx';
import Category from './model/Category';
import { includes } from 'lodash';
import { toast } from 'react-toastify';
import BaseStore from './BaseStore';
import RootStore from "./RootStore";

export default class CategoryStore extends BaseStore {
    categories: IObservableArray<Category> = observable.array([]);

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            categories: observable,
            loadAll: action
        });
    }

    get() {
        return this.categories;
    }

    async loadAll() {
        this.rootStore.uiStateStore.categoriesUpdating = true;
        let categories: Array<Category> = await this.jsonGet(`/api/categories/`);
        runInAction(() => {
            this.categories.replace(categories.map(c => Category.fromJS(c)));
            this.rootStore.uiStateStore.categoriesUpdating = false;
        });
    }

    async addCategory(newCategory: Category) {
        let category = await this.jsonPost(`/api/categories/`, toJS(newCategory));
        runInAction(() => this.categories.push(Category.fromJS(category)));
        this.rootStore.categoriesAndFeedsStore.reload();
    }

    async updateCategory(updatedCategory: Category) {
        let category = await this.jsonPost(`/api/categories/${updatedCategory.id}/`, toJS(updatedCategory));
        runInAction(() => Object.assign(this.categories.find(c => c.id === category.id), category));
        this.rootStore.categoriesAndFeedsStore.reload();
    }

    async deleteCategory(category: Category) {
        let response = await this.httpDelete(`/api/categories/${category.id}/`);
        if (response.ok) {
            runInAction(() => this.categories.remove(category));
            toast.info(`Die Kategorie ${category.name} wurde gelöscht!`);
            this.rootStore.categoriesAndFeedsStore.reload();
        } else {
            if (response.status === 405) {
                response.text().then((responseBody) => {
                    if (includes(responseBody, 'Feeds')) {
                        toast.error(`Die zu löschende Feed Kategorie ${category.name} enthält noch Feeds!\n
                                Bitte zuerst alle Feeds in der Kategorie löschen oder\n
                                einer anderen Kategorie zuteilen`);
                    }
                });
            }
        }
    }
}
