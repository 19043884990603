import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import classNames from 'classnames';
import UserMenu from './UserMenu';
import Search from './Search';
import AddFeed from './AddFeed';
import Reload from './Reload';

@observer
class Header extends React.PureComponent {
    showBurger = false;

    constructor(props: {}) {
        super(props);

        makeObservable(this, {
            showBurger: observable
        });
    }

    render() {
        return (
            <nav className="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <NavLink to="/reader" className="navbar-item">
                        <span className="logo is-2">Reeeder</span>
                    </NavLink>
                    <div className={classNames('navbar-burger', {'is-active': this.showBurger})}
                         onClick={() => runInAction(() => this.showBurger = !this.showBurger)}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
                <img src="calligraphy.svg" style={{marginLeft: '65%'}} className="is-hidden-mobile"
                     alt="header-calligraphy"/>
                <div className={classNames('navbar-menu', {'is-active': this.showBurger})}>
                    <div className="navbar-end">
                        <Reload/>
                        <AddFeed/>
                        <Search/>
                        <UserMenu/>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Header;
