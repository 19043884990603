import * as React from 'react';
import moment from 'moment/moment';
import 'moment/locale/de';

export let dateTime = (date?: number, humanize?: boolean): string => {
    // return nothing, when date is null
    if (date === null) {
        return '';
    }

    let dT = moment(date, 'YYYY-MM-DD\'T\'HH:mm:ss');

    return humanize ? dT.calendar(undefined, {
        sameDay: '[heute um] LT [Uhr]',
        sameElse: 'L LT',
        nextDay: '[morgen um] LT [Uhr]',
        nextWeek: 'dddd [um] LT [Uhr]',
        lastDay: '[gestern um] LT [Uhr]',
        lastWeek: '[letzten] dddd [um] LT [Uhr]'
    }) : dT.format('L LT');
};

const DateTime: React.FunctionComponent<{ dateTime?: number, humanize?: boolean }> = (props) => {
    // render nothing, when date is null
    if (props.dateTime === null) {
        return (<span/>);
    }

    return (<span>{dateTime(props.dateTime, props.humanize)}</span>);
};

export default DateTime;
