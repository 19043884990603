import * as React from 'react';
import classNames from 'classnames';
import Button from './Button';

interface ModalProps {
    label: string;
    show: boolean;
    close: () => void;
    closeText?: string;
    action?: () => void;
    actionDisabled?: boolean;
    actionText?: string;
}

const Modal: React.FunctionComponent<ModalProps> = (props) =>
    (
        <div className={classNames('modal', {'is-active': props.show})}>
            <div className="modal-background"/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.label}</p>
                    <button className="delete" aria-label="close" onClick={() => props.close()}/>
                </header>
                <section className="modal-card-body">
                    {props.children}
                </section>
                <footer className="modal-card-foot">
                    {
                        props.action ? (
                            <Button color="primary" onClick={() => props.action!()}
                                    disabled={props.actionDisabled}>{props.actionText}
                            </Button>
                        ) : ''
                    }
                    <Button onClick={() => props.close()}>{props.closeText || 'Abbrechen'}</Button>
                </footer>

            </div>
        </div>
    );

export default Modal;