import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../stores/RootStore';
import Category from '../../stores/model/Category';
import { FormField, Input, Modal, Select } from '../bulma';

@inject('rootStore')
@observer
class AddFeedDialog extends React.Component<RootStoreProp, {}> {
    isCorrect = false;
    isOpen = false;
    categoryId: number | null = null;
    fetcher: string = "";
    name: string = "";
    url: string = "http://";

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            isCorrect: observable,
            isOpen: observable,
            categoryId: observable,
            fetcher: observable,
            name: observable,
            url: observable,
            close: action
        });
    }

    close() {
        this.categoryId = null;
        this.fetcher = '';
        this.name = '';
        this.url = '';
        this.props.rootStore!.uiStateStore.showAddFeed = false;
    }

    addFeed() {
        if (!(this.categoryId &&
            this.name && this.name.length > 0 &&
            this.url && this.url.length > 0 && this.url !== 'http://' &&
            this.fetcher && this.fetcher.length > 0)) {
            alert('Please make sure everything is filled out correctly!');
            return;
        }

        this.props.rootStore!.feedStore.addNewFeed(this.categoryId, this.fetcher, this.name, this.url);
        this.close();
    }

    render() {
        const categories = this.props.rootStore!.categoryStore.get()
            .filter(c => c.safeForWork || c.safeForWork === this.props.rootStore!.uiStateStore.sfw);
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <Modal label="Feed hinzufügen" show={uiState.showAddFeed} close={() => this.close()}
                   actionDisabled={
                       !this.categoryId || !this.name || this.name.length === 0 || !this.url || this.url.length === 0 || !this.fetcher || this.fetcher.length === 0}
                   action={() => this.addFeed()} actionText="Hinzufügen">
                <FormField label="Kategorie">
                    <div className="select">
                        <Select onChange={(newValue) => this.categoryId = Number(newValue)}>
                            <option key={''} value={0}>Bitte wählen</option>
                            {categories.map((category: Category) => {
                                return (
                                    <option key={category.id}
                                            value={category.id}>{category.name}</option>
                                );
                            })}
                        </Select>
                    </div>
                </FormField>
                <FormField label="Name">
                    <Input onChange={(newValue) => this.name = newValue}/>
                </FormField>
                <FormField label="URL">
                    <Input type="url" placeholder="http://" onChange={(newValue) => this.url = newValue}/>
                </FormField>
                <FormField label="Fetcher">
                    <div className="select">
                        <Select onChange={(newValue) => this.fetcher = newValue}>
                            <option value="">Bitte wählen</option>
                            <option value="rss">RSS/Atom</option>
                            <option value="tumblr">Tumblr</option>
                            <option value="instagram">Instagram</option>
                        </Select>
                    </div>
                </FormField>
            </Modal>
        );
    }
}

export default AddFeedDialog;
