import * as React from 'react';
import { action, makeObservable } from 'mobx';

class Textarea extends React.Component <TextareaProps, {}> {
    constructor(props: TextareaProps) {
        super(props);

        makeObservable(this, {
            textareaChange: action.bound
        });
    }

    textareaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <textarea
                className="textarea"
                value={this.props.value || ''}
                onChange={this.textareaChange}/>
        );
    }
}

interface TextareaProps {
    value?: string,
    onChange: (newValue: string) => void
}

export default Textarea;
