import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../stores/RootStore';
import { action, makeObservable } from 'mobx';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, IconText } from '../../bulma';

@inject('rootStore')
@observer
class ToolbarActionUpdate extends React.Component<RootStoreProp, {}> {
    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            updateFeeds: action
        });
    }

    updateFeeds(forced: boolean = false) {
        this.props.rootStore!.feedStore.updateFeeds(forced);
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <div className="navbar-item">
                <Button color="info" outlined={true} small={true} loading={uiState.updating}
                        onClick={() => this.updateFeeds(true)}
                        title="Update Feeds">
                    <IconText icon={faSyncAlt} text={'Update Feeds'}/>
                </Button>
            </div>
        );
    }
}

export default ToolbarActionUpdate;
