import { action, autorun, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import Item from './model/Item';
import BaseStore from './BaseStore';
import RootStore from "./RootStore";

export default class ItemStore extends BaseStore {
    items: IObservableArray<Item> = observable.array();

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable<ItemStore, "loadItems">(this, {
            items: observable,
            toggleItemCollapse: action,
            loadItems: action
        });
    }

    get() {
        return this.items;
    }

    autoLoad() {
        autorun(() => {

            let uiState = this.rootStore.uiStateStore;

            let categoryId = uiState.selectedCategoryId;
            let feedId = uiState.selectedFeedId;
            let viewMode = uiState.viewMode;
            let itemOrderMode = uiState.itemOrderMode;
            let itemOrderModeDir = uiState.itemOrderModeDir;
            let sfw = uiState.sfw;
            let search = uiState.search;

            this.loadItems(categoryId, feedId, viewMode, itemOrderMode, itemOrderModeDir, null, sfw, search);
        });
    }

    loadMore() {
        let uiState = this.rootStore.uiStateStore;

        let categoryId = uiState.selectedCategoryId;
        let feedId = uiState.selectedFeedId;
        let viewMode = uiState.viewMode;
        let itemOrderMode = uiState.itemOrderMode;
        let itemOrderModeDir = uiState.itemOrderModeDir;
        let after = uiState.after;
        let sfw = uiState.sfw;
        let search = uiState.search;

        this.loadItems(categoryId, feedId, viewMode, itemOrderMode, itemOrderModeDir, after, sfw, search);
    }

    async markItemRead(item: Item) {
        // don't need to mark if already read!
        if (item.readTime !== null) {
            return;
        }
        let json = await this.jsonPatch(`/api/items/${item.id}/markRead`);
        runInAction(() => this.items.find(i => i.id === item.id)!.readTime = json.dateTime);
        this.rootStore.categoriesAndFeedsStore.updateUnreadCounter(item);
    }

    async toggleItemRead(item: Item) {
        let json = await this.jsonPatch(`/api/items/${item.id}/toggleRead`);
        runInAction(() => this.items.find(i => i.id === item.id)!.readTime = json.dateTime);
        this.rootStore.categoriesAndFeedsStore.updateUnreadCounter(item);
    }

    async toggleItemReadLater(item: Item) {
        let json = await this.jsonPatch(`/api/items/${item.id}/toggleReadLater`);
        runInAction(() => this.items.find(i => i.id === item.id)!.readLater = json.value);
    }

    async toggleItemSaved(item: Item) {
        let json = await this.jsonPatch(`/api/items/${item.id}/toggleSaved`);
        runInAction(() => this.items.find(i => i.id === item.id)!.saved = json.value);
    }

    toggleItemCollapse(item: Item) {
        let uiState = this.rootStore.uiStateStore;
        let oldState = uiState.itemCollapsed.get(item.id.toString());
        if (oldState === undefined) {
            oldState = uiState.itemViewMode !== 'expanded';
        }
        uiState.itemCollapsed.set(item.id.toString(), !oldState);
    }

    private async loadItems(categoryId: number | null, feedId: number | null, viewMode: string,
                            itemOrderMode: string, itemOrderModeDir: string, after: number | null,
                            sfw: boolean, search: string) {

        // Update already running?
        if (this.rootStore.uiStateStore.itemsUpdating) {
            return;
        }

        let uiState = this.rootStore.uiStateStore;

        let catOrFeed = categoryId !== null ? 'category' : 'feed';
        let whatId = categoryId !== null ? categoryId : feedId;

        let additionalParams = this.additionalParameters(after, sfw, search);

        this.rootStore.uiStateStore.itemsUpdating = true;

        let loadedItems: Array<Item> = await this.jsonGet(
            `api/items/${catOrFeed}/${whatId}/${viewMode}/orderedBy/` +
            `${itemOrderMode}/${itemOrderModeDir}${additionalParams}`);

        runInAction(() => {
            let newItems = loadedItems.map(item => Item.fromJS(item));
            if (after !== null) {
                this.items.push(...newItems);
            } else {
                this.items.replace(newItems);
            }
            if (newItems.length > 0) {
                uiState.after = newItems[newItems.length - 1].id;
            }
            this.rootStore.uiStateStore.itemsUpdating = false;
        })
    }
}
