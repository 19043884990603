import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import Feed, { BlackListEntry } from '../../stores/model/Feed';
import { inject, observer } from 'mobx-react';
import { values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, Select } from '../bulma';
import { reaction } from 'mobx';

@inject('rootStore')
@observer
class BlacklistEntry extends React.Component<RootStoreProp & { feed: Feed, entry: BlackListEntry }, {}> {

    constructor(props: RootStoreProp & { feed: Feed, entry: BlackListEntry }) {
        super(props);

        reaction(
            () => values(this.props.entry),
            () => this.props.rootStore!.feedStore
                .updateBlacklistEntry(this.props.feed, this.props.entry.id, this.props.entry),
            {delay: 400});
    }

    deleteEntry() {
        this.props.rootStore!.feedStore.deleteBlacklistEntry(this.props.feed, this.props.entry);
    }

    render() {
        return (
            <tr key={this.props.entry.id}>
                <td>
                    <div className="select">
                        <Select value={this.props.entry.field}
                                onChange={(newValue) => this.props.entry.field = newValue}>
                            <option value="TITLE">Titel</option>
                            <option value="TEXT">Text</option>
                        </Select>
                    </div>
                </td>
                <td>
                    <Input value={this.props.entry.expression}
                           onChange={(newValue) => this.props.entry.expression = newValue}
                           validations="regex"/>
                </td>
                <td>
                    <Button onClick={() => this.deleteEntry()}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                </td>
            </tr>
        );
    }
}

export default BlacklistEntry;
