import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../stores/RootStore';
import { action, makeObservable } from 'mobx';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, IconText } from '../../bulma';

@inject('rootStore')
@observer
class ActionMarkRead extends React.Component<RootStoreProp, {}> {
    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            markSelectionAsRead: action
        });
    }

    markSelectionAsRead() {
        this.props.rootStore!.categoriesAndFeedsStore.markSelectionAsRead();
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <div className="navbar-item">
                <Button color="info" outlined={true} small={true}
                        onClick={() => this.markSelectionAsRead()}
                        title="Ausgewählte als gelesen markieren"
                        disabled={uiState.selectedCategoryId === null && uiState.selectedFeedId === null}>
                    <IconText icon={faCheckCircle} text={'Ausgewählte als gelesen markieren'}/>
                </Button>
            </div>
        );
    }
}

export default ActionMarkRead;
