import * as React from 'react';
import { githubLogin, googleLogin, microsoftLogin } from '../oauth';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGooglePlus, faWindows } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

class Login extends React.Component<RouteComponentProps<{}>, {}> {

    render() {
        const SocialButton: React.FunctionComponent<{ icon: IconProp, title: string, color: string, loginFunction: () => void }>
            = (props) => (
            <a className={`button is-block button-login-${props.color}`} href="#/"
               onClick={() => props.loginFunction()}>
                <span className="icon">
                    <FontAwesomeIcon icon={props.icon}/>
                </span>
                <span>{props.title}</span>
            </a>);

        return (
            <section className="hero is-fullheight is-light">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-3">
                                <h1 className="title is-1 has-text-centered logo">Reeeder</h1>
                                <h3 className="subtitle has-text-centered">Anmelden</h3>
                                <div className="box">
                                    <div className="column">
                                        <SocialButton icon={faWindows} title="Microsoft Login" color="microsoft"
                                                      loginFunction={() => microsoftLogin(this.props.history)}/>
                                        <br/>
                                        <SocialButton icon={faGooglePlus} title="Google" color="googleplus"
                                                      loginFunction={() => googleLogin(this.props.history)}/>
                                        <br/>
                                        <SocialButton icon={faGithub} title="GitHub" color="github"
                                                      loginFunction={() => githubLogin(this.props.history)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>);
    }
}

export default withRouter(Login);