import { makeObservable, observable, ObservableMap } from 'mobx';
import RootStore from './RootStore';
import BaseStore from './BaseStore';

export default class UiStateStore extends BaseStore {

    selectedCategoryId: number | null;
    selectedFeedId: number | null;
    after: number | null;
    updating: boolean;
    categoriesAndFeedsUpdating: boolean;
    categoriesUpdating: boolean;
    feedsUpdating: boolean;
    itemsUpdating: boolean;
    itemLayout: string;
    viewMode: string;
    itemViewMode: string;
    itemOrderMode: string;
    itemOrderModeDir: string;
    itemCollapsed: ObservableMap<String, boolean>;
    itemSelected: number;
    markReadOnScroll: boolean;
    sfw: boolean;
    fitImagesToScreen: boolean;
    search: string;
    showAddFeed: boolean;
    showAddBlacklistEntry: boolean;
    showAddBlacklistEntryFeedId: number;
    showAddBlacklistEntryExpression: string;
    showUpdateErrorFeedId: number | null;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            selectedCategoryId: observable,
            selectedFeedId: observable,
            after: observable,
            updating: observable,
            categoriesAndFeedsUpdating: observable,
            categoriesUpdating: observable,
            feedsUpdating: observable,
            itemsUpdating: observable,
            itemLayout: observable,
            viewMode: observable,
            itemViewMode: observable,
            itemOrderMode: observable,
            itemOrderModeDir: observable,
            itemCollapsed: observable,
            itemSelected: observable,
            markReadOnScroll: observable,
            sfw: observable,
            fitImagesToScreen: observable,
            search: observable,
            showAddFeed: observable,
            showAddBlacklistEntry: observable,
            showAddBlacklistEntryFeedId: observable,
            showAddBlacklistEntryExpression: observable,
            showUpdateErrorFeedId: observable
        });

        this.selectedCategoryId = -1;
        this.selectedFeedId = null;
        this.after = null;
        this.updating = false;
        this.categoriesAndFeedsUpdating = false;
        this.categoriesUpdating = false;
        this.feedsUpdating = false;
        this.itemsUpdating = false;
        this.itemLayout = 'columns';
        this.viewMode = 'unread';
        this.itemViewMode = 'collapsed';
        this.itemOrderMode = 'newest';
        this.itemOrderModeDir = 'desc';
        this.itemCollapsed = observable.map();
        this.itemSelected = -1;
        this.markReadOnScroll = true;
        this.sfw = true;
        this.fitImagesToScreen = false;
        this.search = '';
        this.showAddFeed = false;
        this.showAddBlacklistEntry = false;
        this.showAddBlacklistEntryFeedId = -1;
        this.showAddBlacklistEntryExpression = '';
        this.showUpdateErrorFeedId = null;
    }
}
