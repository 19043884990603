import * as React from 'react';
import { includes, toLower } from 'lodash';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import Feed from './Feed';
import UpdateError from './UpdateError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../bulma';

@inject('rootStore')
@observer
class Feeds extends React.Component<RootStoreProp, {}> {

    feedsFilter: string = '';

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            feedsFilter: observable
        });
    }

    miniStats() {
        let catIDsNotSafeForWork = this.props.rootStore!.categoryStore.get()
            .filter(c => !c.safeForWork)
            .map(c => c.id);

        let feeds = this.props.rootStore!.feedStore.get()
            .filter(f => f.safeForWork || f.safeForWork === this.props.rootStore!.uiStateStore.sfw)
            .filter(f => this.props.rootStore!.uiStateStore.sfw ? !catIDsNotSafeForWork.find(c => c === f.categoryId) : true);

        let totalFeeds = 0;
        let deactivated = 0;
        let inError = 0;
        feeds.forEach((feed) => {
            totalFeeds++;
            if (!feed.active) {
                deactivated++;
            }
            if (feed.hasError) {
                inError++;
            }
        });

        return {
            total: totalFeeds,
            deactivated: deactivated,
            error: inError
        };
    }

    render() {
        let miniStats = this.miniStats();

        let catIDsNotSafeForWork = this.props.rootStore!.categoryStore.get()
            .filter(c => !c.safeForWork)
            .map(c => c.id);

        let feeds = this.props.rootStore!.feedStore.get()
            .filter(f => includes(toLower(f.name), toLower(this.feedsFilter)))
            .filter(f => f.safeForWork || f.safeForWork === this.props.rootStore!.uiStateStore.sfw)
            .filter(f => this.props.rootStore!.uiStateStore.sfw ? !catIDsNotSafeForWork.find(c => c === f.categoryId) : true)
            .sort((fa, fb) =>
                toLower(fa.name) === toLower(fb.name) ? 0 : toLower(fa.name) > toLower(fb.name) ? 1 : -1);

        return (
            <div className="card">
                <div className="card-header is-light">
                    <div className="card-header-title">Feeds</div>
                    <div className="navbar-item">
                        <Input small={true} rounded={true} placeholder="Suche..." validations="regex"
                               onChange={(newValue) => this.feedsFilter = newValue}/>
                    </div>
                    <div className="card-header-right">
                        <small>
                            Total: {miniStats.total} - Deaktiviert: {miniStats.deactivated}
                            {(miniStats.error > 0) ? (
                                <span> - <span style={{color: 'red'}}>Fehler: {miniStats.error}</span></span>) : ''}
                        </small>
                    </div>
                </div>
                <div className="card-content">
                    {(feeds.length === 0) ? (
                        <span><FontAwesomeIcon icon={faSyncAlt} spin={true} size={'sm'}/> Lade...</span>
                    ) : (
                        feeds.map((feed) => {
                            return (<Feed key={feed.id} feed={feed}/>);
                        })
                    )}
                    <UpdateError/>
                </div>
            </div>
        );
    }
}

export default Feeds;
