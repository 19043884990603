import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import * as ModelCategory from '../../stores/model/Category';
import { values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Input } from '../bulma';
import { reaction } from 'mobx';

interface CategoryProps {
    category: ModelCategory.Category;
}

@inject('rootStore')
@observer
class Category extends React.Component<RootStoreProp & CategoryProps, {}> {
    constructor(props: RootStoreProp & CategoryProps) {
        super(props);

        reaction(
            () => values(this.props.category),
            () => this.props.rootStore!.categoryStore.updateCategory(this.props.category),
            {delay: 400});
    }

    deleteCategory() {
        this.props.rootStore!.categoryStore.deleteCategory(this.props.category);
    }

    render() {
        return (
            <tr key={this.props.category.id}>
                <td>
                    <Input small={true}
                           value={this.props.category.name}
                           onChange={(newValue) => this.props.category.name = newValue}/>
                </td>
                <td>
                    <Checkbox id={`categorySafeForWork${this.props.category.id}`}
                              value={this.props.category.safeForWork}
                              onChange={(newValue) => this.props.category.safeForWork = newValue}/>
                </td>
                <td>
                    <Checkbox id={`categorySyncApi${this.props.category.id}`}
                              value={this.props.category.syncApi}
                              onChange={(newValue) => this.props.category.syncApi = newValue}/>
                </td>
                <td>
                    <Button color="primary" small={true} onClick={() => this.deleteCategory()}
                            title="Löschen">
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                </td>
            </tr>
        );
    }
}

export default Category;
