import * as React from 'react';
import { RootStoreProp } from '../../stores/RootStore';
import * as ModelCategory from '../../stores/model/Category';
import { inject, observer } from 'mobx-react';
import Category from './Category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import SimpleCard from '../bulma/SimpleCard';

@inject('rootStore')
@observer
class Categories extends React.Component<RootStoreProp, {}> {

    addCategory() {
        this.props.rootStore!.categoryStore.addCategory(new ModelCategory.Category(
            0,
            true,
            'Neue Kategorie',
            true
        ));
    }

    render() {
        let categories = this.props.rootStore!.categoryStore.get()
            .filter(c => c.safeForWork || c.safeForWork === this.props.rootStore!.uiStateStore.sfw);

        return (
            <SimpleCard title="Feed Kategorien">
                {(categories.length === 0) ? (
                    <span><FontAwesomeIcon icon={faSyncAlt} spin={true} size={'sm'}/> Lade...</span>
                ) : (
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Safe for Work</th>
                            <th>Sync over API</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories.map((category) => {
                            return (<Category key={category.id} category={category}/>);
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>
                                <a className="button is-primary" href="#/" onClick={() => this.addCategory()}>
                                        <span className="icon is-small">
                                          <FontAwesomeIcon icon={faPlus}/>
                                        </span>
                                    <span>Hinzufügen</span>
                                </a>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        </tfoot>
                    </table>
                )}
            </SimpleCard>
        );
    }
}

export default Categories;