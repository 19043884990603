import * as React from 'react';
import './Toolbar.scss';
import ViewMode from './ViewMode';
import ItemMode from './ItemMode';
import ActionMarkRead from './ActionMarkRead';
import ActionUpdate from './ActionUpdate';
import ItemLayout from './ItemLayout';

const Toolbar = () => (
    <nav className="navbar is-toolbar has-shadow">
        <div className="navbar-menu is-active">
            <div className="navbar-start">
                <ViewMode/>
                <div className="navbar-item"/>
                <div className="navbar-item"/>
                <div className="navbar-item"/>
                <ActionMarkRead/>
                <ActionUpdate/>
            </div>
            <div className="navbar-end">
                <ItemLayout/>
                <ItemMode/>
            </div>
        </div>
    </nav>);

export default Toolbar;