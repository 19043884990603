import RootStore from './RootStore';
import { getStoredToken } from '../oauth';
import * as qs from 'querystring';

export default class BaseStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    static handleErrors(response: Response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    jsonGet(url: string): Promise<any> {
        return fetch(url, BaseStore.req('GET'))
            .then(BaseStore.handleErrors)
            .then((response: Response) => {
                return response.json();
            });
    }

    httpPatch(url: string): Promise<any> {
        return fetch(url, BaseStore.req('PATCH'));
    }

    jsonPatch(url: string): Promise<any> {
        return this.httpPatch(url)
            .then(BaseStore.handleErrors)
            .then((response: Response) => {
                return response.json();
            });
    }

    jsonPost(url: string, payload: {}): Promise<any> {
        return fetch(url, BaseStore.req('POST', JSON.stringify(payload)))
            .then(BaseStore.handleErrors)
            .then((response: Response) => {
                return response.json();
            });
    }

    httpDelete(url: string): Promise<Response> {
        return fetch(url, BaseStore.req('DELETE'));
    }

    jsonDelete(url: string): Promise<any> {
        return this.httpDelete(url)
            .then(BaseStore.handleErrors)
            .then((response: Response) => {
                return response.json();
            });
    }

    // noinspection JSMethodCanBeStatic
    additionalParameters(after: number | null, sfw: boolean | null, search: string | null) {

        let params: {
            after: number | number[]
            sfw: boolean | boolean[];
            search: string | string[];
        } = {after: [], sfw: [], search: []};

        if (after !== null) {
            params.after = after;
        }
        if (sfw !== null && !sfw) {
            params.sfw = sfw;
        }
        if (search !== null && search !== '') {
            params.search = search;
        }

        if (Object.keys(params).length === 0) {
            return '';
        }

        return '?' + qs.stringify(params);
    }

    private static req(method: string, body: string | null = null) {
        let req: RequestInit = {
            method: method,
            headers: new Headers([
                ['Content-Type', 'application/json'],
                ['Authorization', `Bearer ${getStoredToken()}`]
            ]),
            credentials: 'same-origin', // By default, fetch won't send any cookies to the server
        };

        if (body !== null) req.body = body;

        return req;
    }
}
