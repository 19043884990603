import CategoriesAndFeedsStore from './CategoriesAndFeedsStore';
import CategoryStore from './CategoryStore';
import FeedStore from './FeedStore';
import UiStateStore from './UiStateStore';
import AuthStore from './AuthStore';
import ItemStore from './ItemStore';

export default class RootStore {

    uiStateStore: UiStateStore;

    authStore: AuthStore;

    categoriesAndFeedsStore: CategoriesAndFeedsStore;

    categoryStore: CategoryStore;

    feedStore: FeedStore;

    itemStore: ItemStore;

    constructor() {
        this.uiStateStore = new UiStateStore(this);
        this.authStore = new AuthStore(this);
        this.categoriesAndFeedsStore = new CategoriesAndFeedsStore(this);
        this.categoryStore = new CategoryStore(this);
        this.feedStore = new FeedStore(this);
        this.itemStore = new ItemStore(this);
    }
}

export interface RootStoreProp {
    rootStore?: RootStore;
}