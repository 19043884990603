import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../../stores/RootStore';
import './ItemColumns.scss';
import * as ItemModel from '../../../../stores/model/Item';
import AddBlacklist from '../../AddBlacklist';
import { throttle } from 'lodash';
import { HotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import List from './list/List';
import ItemView from './item/ItemView';
import { action, makeObservable } from 'mobx';

@inject('rootStore')
@observer
class ItemColumns extends React.Component<RootStoreProp, {}> {

    keyMap = {
        next: ['down', 'right'],
        previous: ['up', 'left'],
        saved: 's',
        readLater: 'r',
        unread: 'g'
    };

    keyHandlers = {
        'next': () => this.nextItem(),
        'previous': () => this.previousItem(),
        'saved': () => this.toggleSaved(),
        'readLater': () => this.toggleReadLater(),
        'unread': () => this.toggleRead()
    };

    loadMore: () => void;

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            nextItem: action,
            previousItem: action,
            closeNewBlacklistItem: action
        });

        this.loadMore = throttle(() => this.handleLoadMore(), 2000, {leading: false});
    }

    currentItem(): ItemModel.Item | undefined {
        return this.props.rootStore!.itemStore.get().find(i => i.id === this.props.rootStore!.uiStateStore.itemSelected);
    }

    nextItem() {
        const uiState = this.props.rootStore!.uiStateStore;
        const itemStore = this.props.rootStore!.itemStore;

        let currentItemIndex = itemStore.get()
            .findIndex(i => i.id === uiState.itemSelected);

        let nextItem = itemStore.get().find((value, index) => index === currentItemIndex + 1);

        if (nextItem) {
            window.scrollTo(0, 0);
            uiState.itemSelected = nextItem.id;
            this.props.rootStore!.itemStore.markItemRead(nextItem);
        }

        if (currentItemIndex === itemStore.get().length - 5) {
            this.loadMore();
        }
    }

    previousItem() {
        const uiState = this.props.rootStore!.uiStateStore;
        const itemStore = this.props.rootStore!.itemStore;

        let currentItemIndex = itemStore.get()
            .findIndex(i => i.id === uiState.itemSelected);

        let prevItem = itemStore.get().find((value, index) => index === currentItemIndex - 1);

        if (prevItem) {
            window.scrollTo(0, 0);
            uiState.itemSelected = prevItem.id;
            this.props.rootStore!.itemStore.markItemRead(prevItem);
        }
    }

    handleLoadMore() {
        this.props.rootStore!.itemStore.loadMore();
    }

    toggleSaved() {
        let currentItem = this.currentItem();
        if (currentItem) {
            this.props.rootStore!.itemStore.toggleItemSaved(currentItem);
            toast.info(`"${currentItem.title}" was ${!currentItem.saved ? "" : "un"}marked as saved`, {autoClose: 1500})
        }
    }

    toggleReadLater() {
        let currentItem = this.currentItem();
        if (currentItem) {
            this.props.rootStore!.itemStore.toggleItemReadLater(currentItem);
            toast.info(`"${currentItem.title}" was ${!currentItem.readLater ? "added" : "removed"} from ReadLater list`, {autoClose: 1500})
        }
    }

    toggleRead() {
        let currentItem = this.currentItem();
        if (currentItem) {
            this.props.rootStore!.itemStore.toggleItemRead(currentItem);
        }
    }

    addNewBlacklistItem(feedId: number, expression: string) {
        this.props.rootStore!.feedStore.addNewBlacklistEntry(feedId, expression);
    }

    closeNewBlacklistItem() {
        this.props.rootStore!.uiStateStore.showAddBlacklistEntry = false;
    }

    render() {
        let itemStore = this.props.rootStore!.itemStore;

        let items = itemStore.get();

        let currentItem = this.currentItem();

        return (
            <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
                <div className="is-item-list">
                    <AddBlacklist add={(feedId, expression) => this.addNewBlacklistItem(feedId, expression)}
                                  close={() => this.closeNewBlacklistItem()}/>
                    <div className="columns">
                        <div className="column is-3-tablet is-2-desktop is-2-widescreen">
                            <List items={items}/>
                        </div>
                        <div className="column is-content">
                            <ItemView currentItem={currentItem}/>
                        </div>
                    </div>
                </div>
            </HotKeys>);
    }
}

export default ItemColumns;
