import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../stores/RootStore';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faList } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../bulma';

@inject('rootStore')
@observer
class ItemLayout extends React.Component<RootStoreProp, {}> {

    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            itemLayout: action
        });
    }

    itemLayout(layout: string) {
        window.scrollTo(0, 0);
        this.props.rootStore!.uiStateStore.itemLayout = layout;
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <div className="navbar-item">
                <div className="buttons has-addons">
                    <Button color="info" outlined={true} small={true}
                            selected={uiState.itemLayout === 'list'}
                            onClick={() => this.itemLayout('list')}>
                        <FontAwesomeIcon icon={faList}/>
                    </Button>
                    <Button color="info" outlined={true} small={true}
                            selected={uiState.itemLayout === 'columns'}
                            onClick={() => this.itemLayout('columns')}>
                        <FontAwesomeIcon icon={faColumns}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default ItemLayout;
