import { FeedAndCount } from './FeedAndCount';
import { IObservableArray, makeAutoObservable, observable } from 'mobx';

export class CategoryAndFeeds {
    id: number;
    name: string;
    feeds: IObservableArray<FeedAndCount>;
    count: number;

    static fromJS(object: CategoryAndFeeds): CategoryAndFeeds {
        let feedsFromJs = (object.feeds as Array<FeedAndCount>).map(f => FeedAndCount.fromJS(f));
        return new CategoryAndFeeds(object.id, object.name, feedsFromJs, object.count);
    }

    constructor(id: number, name: string, feeds: Array<FeedAndCount>, count: number) {
        makeAutoObservable(this);

        this.id = id;
        this.name = name;
        this.feeds = observable.array(feeds);
        this.count = count;
    }
}
