import * as React from 'react';
import * as ItemModel from '../../../../../stores/model/Item';
import Categories from './Categories';
import Actions from './Actions';
import Header from './Header';


const ItemView: React.FunctionComponent<{ currentItem: ItemModel.Item | undefined }> = (props) => (
    props.currentItem === undefined ? (<div/>) : (<div
            className={'card'}>
            <Header item={props.currentItem}/>
            <div className={'card-content'}>
                <div className="is-clearfix"
                     dangerouslySetInnerHTML={{__html: props.currentItem.content}}/>
            </div>
            <Categories item={props.currentItem}/>
            <Actions item={props.currentItem}/>
        </div>
    )
);

export default ItemView;
