import * as React from 'react';
import classNames from 'classnames';
import { RootStoreProp } from '../../../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import * as ItemModel from '../../../../../stores/model/Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCheckCircle, faHeart } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import '../list/Item.scss';
import ItemStore from '../../../../../stores/ItemStore';

interface ItemProps {
    item: ItemModel.Item;
}

@inject('rootStore')
@observer
class Actions extends React.Component<ItemProps & RootStoreProp, {}> {

    itemStore: ItemStore;

    constructor(props: ItemProps & RootStoreProp) {
        super(props);

        this.itemStore = this.props.rootStore!.itemStore;
    }

    toggleSaved() {
        this.itemStore.toggleItemSaved(this.props.item);
    }

    toggleReadLater() {
        this.itemStore.toggleItemReadLater(this.props.item);
    }

    toggleRead() {
        this.itemStore.toggleItemRead(this.props.item);
    }

    render() {
        let item = this.props.item;
        return (
            <div className={'card-footer'}>
                <div className="card-footer-item" onClick={() => this.toggleReadLater()}>
                    <FontAwesomeIcon icon={item.readLater ? faBookmark : far.faBookmark} size={'sm'}/>
                </div>
                <div className="card-footer-item" onClick={() => this.toggleSaved()}>
                    <FontAwesomeIcon icon={item.saved ? faHeart : far.faHeart} size={'sm'}
                                     className={classNames({'text-danger': item.saved})}/>
                </div>
                <div className="card-footer-item" onClick={() => this.toggleRead()}>
                    <FontAwesomeIcon icon={item.readTime ? faCheckCircle : far.faCheckCircle} size={'sm'}/>
                </div>
            </div>);
    }
}

export default Actions;
