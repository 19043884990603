import { action, IObservableArray, makeObservable, observable, ObservableMap, runInAction, toJS, } from 'mobx';
import Feed, { BlackListEntry, UpdateError } from './model/Feed';
import { includes } from 'lodash';
import { toast } from 'react-toastify';
import BaseStore from './BaseStore';
import RootStore from "./RootStore";

export default class FeedStore extends BaseStore {
    feeds: IObservableArray<Feed> = observable.array([]);
    feedBlacklist: ObservableMap<String, IObservableArray<BlackListEntry>> = observable.map();
    feedUpdateErrors: ObservableMap<String, IObservableArray<UpdateError>> = observable.map();

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            feeds: observable,
            feedBlacklist: observable,
            feedUpdateErrors: observable,
            loadAll: action,
            updateFeeds: action,
            updateSingleFeed: action
        });
    }

    get() {
        return this.feeds;
    }

    getById(feedId: number): Feed | undefined {
        return this.feeds.find(f => f.id === feedId);
    }

    async loadAll() {
        this.rootStore.uiStateStore.feedsUpdating = true;
        let feeds: Array<Feed> = await this.jsonGet(`/api/feeds/`);
        runInAction(() => {
            this.feeds.replace(feeds.map(f => Feed.fromJS(f)));
            this.rootStore.uiStateStore.feedsUpdating = false;
        });
    }

    async updateFeeds(forced: boolean = false) {
        let uiState = this.rootStore.uiStateStore;

        uiState.updating = true;
        await this.httpPatch(`/api/feeds/update${forced ? '?force=true' : ''}`);
        runInAction(() => {
            uiState.after = null;
            uiState.updating = false;
        });

        this.rootStore.categoriesAndFeedsStore.reload();
        this.loadAll();
    }

    async updateSingleFeed(feedId: number) {
        let updateFeed = this.feeds.find(f => f.id === feedId);
        if (updateFeed) {
            updateFeed.isUpdating = true;
        }

        let feed = await this.jsonPatch(`/api/feeds/${feedId}/update`);
        runInAction(() => {
            let feedToUpdate = this.feeds.find(f => f.id === feedId);
            Object.assign(feedToUpdate, feed);
            if (feedToUpdate) {
                feedToUpdate.isUpdating = false;
            }
        });
        this.rootStore.categoriesAndFeedsStore.reload();
    }

    async addNewFeed(categoryId: number, fetcher: string, name: string, url: string) {
        await this.jsonPost('/api/feeds/', {
            categoryId: categoryId,
            fetcher: fetcher,
            name: name,
            url: url
        });
        runInAction(() => this.rootStore.uiStateStore.showAddFeed = false);
        this.rootStore.categoriesAndFeedsStore.reload();
        this.loadAll();
    }

    async addNewBlacklistEntry(feedId: number, expression: string) {
        await this.jsonPost(`/api/feeds/${feedId}/blacklist`, {
            feedId: feedId,
            field: 'TITLE',
            expression: expression
        });
        runInAction(() => this.rootStore.uiStateStore.showAddBlacklistEntry = false);
    }


    async updateFeed(updatedFeed: Feed) {
        let feed = await this.jsonPost(`/api/feeds/${updatedFeed.id}/`, toJS(updatedFeed));
        runInAction(() => Object.assign(this.feeds.find(f => f.id === feed.id), feed));
        this.rootStore.categoriesAndFeedsStore.reload();
    }

    async reactivateFeed(feedId: number) {
        let feed = await this.jsonPatch(`/api/feeds/${feedId}/reactivate`);
        runInAction(() => Object.assign(this.feeds.find(f => f.id === feedId), feed));
    }

    async markReadByBlacklist(feed: Feed) {
        let json = await this.jsonPatch(`/api/feeds/${feed.id}/markReadByBlacklist`);
        toast.info(`Feed ${feed.name}: Es wurden ${json.value} Einträge als gelesen markiert`);
        this.rootStore.categoriesAndFeedsStore.reload();
    }

    async deleteFeed(feed: Feed) {
        let response = await this.httpDelete(`/api/feeds/${feed.id}/`);
        if (response.ok) {
            runInAction(() => this.feeds.remove(feed));
            toast.info(`Der Feed ${feed.name} wurde gelöscht!`);
        } else {
            if (response.status === 405) {
                response.text().then((responseBody) => {
                    let errorCause = '';
                    if (includes(responseBody, 'unread')) {
                        errorCause = 'ungelesene';
                    } else if (includes(responseBody, 'readLater')) {
                        errorCause = 'ReadLater';
                    } else if (includes(responseBody, 'saved')) {
                        errorCause = 'gespeicherte';
                    }

                    toast.error(`Der Feed ${feed.name} enthält noch ${errorCause} Einträge und kann daher ` +
                        `nicht gelöscht werden`);
                });
            }
        }
    }

    async fetchBlacklist(feed: Feed) {
        let blackListForFeed = this.feedBlacklist.get(feed.id.toString());
        if (!blackListForFeed) {
            let blacklist: Array<BlackListEntry> = await this.jsonGet(`/api/feeds/${feed.id}/blacklist/`);
            let blackListForFeed = observable.array(blacklist.map(be => BlackListEntry.fromJS(be)));
            runInAction(() => this.feedBlacklist.set(feed.id.toString(), blackListForFeed));
        }
    }

    getBlacklist(feed: Feed) {
        return this.feedBlacklist.get(feed.id.toString());
    }

    async addBlacklistEntry(feed: Feed, newEntry: { field: string, expression: string }) {
        let blacklistEntry = await this.jsonPost(`/api/feeds/${feed.id}/blacklist`, newEntry);
        let feedBlackList = this.feedBlacklist.get(feed.id.toString());
        runInAction(() => {
            if (feedBlackList) feedBlackList.push(BlackListEntry.fromJS(blacklistEntry));
        });
    }

    async updateBlacklistEntry(feed: Feed, entryId: string, updatedEntry: BlackListEntry) {
        let blacklistEntry = await this.jsonPost(`/api/feeds/${feed.id}/blacklist/${entryId}`, toJS(updatedEntry));
        let feedBlackList = this.feedBlacklist.get(feed.id.toString());
        runInAction(() => {
            if (feedBlackList) {
                Object.assign(feedBlackList.find(be => be.id === entryId), blacklistEntry);
            }
        });
    }

    async deleteBlacklistEntry(feed: Feed, entry: BlackListEntry) {
        await this.httpDelete(`/api/feeds/${feed.id}/blacklist/${entry.id}`);
        runInAction(() => {
            let feedBlackList = this.feedBlacklist.get(feed.id.toString());
            if (feedBlackList) {
                let entryToRemove = feedBlackList.find(be => be.id === entry.id);
                if (entryToRemove) {
                    feedBlackList.remove(entryToRemove);
                    toast.info(`Blacklist Eintrag ${entry.expression} für Feed ${feed.name} wurde gelöscht!`);
                }
            }
        });
    }

    async fetchUpdateErrors(feed: Feed) {
        let updateErrorsForFeed = this.feedUpdateErrors.get(feed.id.toString());
        if (!updateErrorsForFeed) {
            let updateErrors: Array<UpdateError> = await this.jsonGet(`/api/feeds/${feed.id}/updateErrors`);
            updateErrorsForFeed = observable.array(updateErrors.map(be => UpdateError.fromJS(be)));
            runInAction(() => this.feedUpdateErrors.set(feed.id.toString(), updateErrorsForFeed!));
        }
    }

    getUpdateErrors(feed: Feed) {
        return this.feedUpdateErrors.get(feed.id.toString()) || Array<UpdateError>();
    }
}
