import * as React from 'react';
import './Sidebar.scss';
import { RootStoreProp } from '../../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRss } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { FeedAndCount } from '../../../stores/model/FeedAndCount';
import { Badge } from '../../bulma';

@inject('rootStore')
@observer
class Feed extends React.Component<{ feedProvider: () => FeedAndCount } & RootStoreProp, {}> {

    constructor(props: { feedProvider: () => FeedAndCount } & RootStoreProp) {
        super(props);

        makeObservable(this, {
            onSelectFeed: action
        });
    }

    onSelectFeed(event: React.MouseEvent<HTMLAnchorElement>, feedId: number | null) {
        event.preventDefault();
        const uiState = this.props.rootStore!.uiStateStore;
        if (feedId !== null) {
            uiState.selectedFeedId = feedId;
            uiState.selectedCategoryId = null;
            uiState.after = null;
            window.scrollTo(0, 0);
        }
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;
        let feed = this.props.feedProvider();

        return (
            <li>
                <a className={classNames(
                    {'is-active': uiState.selectedFeedId === feed.id})}
                   href="#/"
                   onClick={(e) =>
                       this.onSelectFeed(e, feed.id)}>
                    {(feed.favicon !== null) ? (
                        <img alt="feedFavicon"
                             src={
                                 `data:${feed.faviconMime};` +
                                 `base64,${feed.favicon}`}
                             className="feed-favicon"/>
                    ) : (
                        <FontAwesomeIcon icon={faRss}
                                         className={'feed-favicon'}/>
                    )}
                    <small>{feed.name}</small>
                    {(feed.count > 0) ? (
                        <Badge value={feed.count}/>
                    ) : ''}
                </a>
            </li>);
    }
}

export default Feed;
