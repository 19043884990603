import * as React from 'react';
import classNames from 'classnames';

const Button: React.FunctionComponent<{
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    color?: string,
    small?: boolean,
    outlined?: boolean,
    active?: boolean,
    selected?: boolean,
    loading?: boolean,
    title?: string,
    disabled?: boolean
}> = (props) => {

    return (
        <button
            className={classNames(
                'button',
                {'is-small': props.small},
                {'is-outlined': props.outlined && !props.selected},
                {'is-primary': props.color === 'primary'},
                {'is-info': props.color === 'info'},
                {'is-warning': props.color === 'warning'},
                {'is-danger': props.color === 'danger'},
                {'is-active': props.active},
                {'is-selected': props.selected},
                {'is-loading': props.loading}
            )}
            onClick={props.onClick}
            title={props.title}
            disabled={props.disabled}>
            {props.children}
        </button>
    );
};

export default Button;
