import { makeAutoObservable } from 'mobx';

export class Feed {
    id: number;
    categoryId: number;
    name: string;
    url?: string;
    pubDate?: number;
    lastUpdated?: number;
    deleteAfterDays?: number;
    updateInterval?: number;
    active?: boolean;
    pause?: boolean;
    safeForWork?: boolean;
    syncApi?: boolean;
    fetcher?: string;
    followUrl?: boolean;
    followSelectors?: string;
    followProcess?: boolean;
    followProcessRules?: string;
    preprocess?: boolean;
    preprocessRules?: string;
    imageFeed?: boolean;
    favicon?: string;
    faviconMime?: string;
    faviconUpdated?: number;
    hasError?: boolean;
    lastError?: number;
    hasBlacklist?: boolean;
    isUpdating?: boolean;

    static fromJS(object: Feed): Feed {
        return new Feed(
            object.id, object.categoryId, object.name, object.url, object.pubDate, object.lastUpdated,
            object.deleteAfterDays, object.updateInterval, object.active, object.pause, object.safeForWork, object.syncApi, object.fetcher,
            object.followUrl, object.followSelectors, object.followProcess, object.followProcessRules, object.preprocess,
            object.preprocessRules, object.imageFeed, object.favicon, object.faviconMime, object.faviconUpdated,
            object.hasError, object.lastError, object.hasBlacklist);
    }

    constructor(id: number, categoryId: number, name: string, url?: string, pubDate?: number, lastUpdated?: number,
                deleteAfterDays?: number, updateInterval?: number, active?: boolean, pause?: boolean, safeForWork?: boolean,
                syncApi?: boolean, fetcher?: string, followUrl?: boolean, followSelectors?: string,
                followProcess?: boolean, followProcessRules?: string, preprocess?: boolean, preprocessRules?: string,
                imageFeed?: boolean, favicon?: string, faviconMime?: string, faviconUpdated?: number,
                hasError?: boolean, lastError?: number, hasBlacklist?: boolean) {
        makeAutoObservable(this);

        this.id = id;
        this.categoryId = categoryId;
        this.name = name;
        this.url = url;
        this.pubDate = pubDate;
        this.lastUpdated = lastUpdated;
        this.deleteAfterDays = deleteAfterDays;
        this.updateInterval = updateInterval;
        this.active = active;
        this.pause = pause;
        this.safeForWork = safeForWork;
        this.syncApi = syncApi;
        this.fetcher = fetcher;
        this.followUrl = followUrl;
        this.followSelectors = followSelectors;
        this.followProcess = followProcess;
        this.followProcessRules = followProcessRules;
        this.preprocess = preprocess;
        this.preprocessRules = preprocessRules;
        this.imageFeed = imageFeed;
        this.favicon = favicon;
        this.faviconMime = faviconMime;
        this.faviconUpdated = faviconUpdated;
        this.hasError = hasError;
        this.lastError = lastError;
        this.hasBlacklist = hasBlacklist;
    }
}

export class UpdateError {
    date: number;
    error: string;

    static fromJS(object: UpdateError) {
        return new UpdateError(object.date, object.error);
    }

    constructor(date: number, error: string) {
        makeAutoObservable(this);

        this.date = date;
        this.error = error;
    }
}

export class BlackListEntry {
    id: string;
    field: string;
    expression: string;

    static fromJS(object: BlackListEntry) {
        return new BlackListEntry(object.id, object.field, object.expression);
    }

    constructor(id: string, field: string, expression: string) {
        makeAutoObservable(this);

        this.id = id;
        this.field = field;
        this.expression = expression;
    }
}

export default Feed;
