import * as React from 'react';
import './Loading.scss';

const Loading = () => (
    <section className="hero is-fullheight is-light">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-3">
                        <div className="column">
                            <div className="loadingContainer">
                                <div className="loading"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Loading;