import * as React from 'react';
import classNames from 'classnames';
import Item from '../../../../../stores/model/Item';

const Categories: React.FunctionComponent<{ item: Item }> = (props) => (
    <div className={classNames('card-content')}>
        <div className="tags">
            {
                props.item.categories.map((tag: string) =>
                    <span key={tag} className="tag">{tag}</span>
                )
            }
        </div>
    </div>
);

export default Categories;