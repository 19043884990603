import { makeAutoObservable } from 'mobx';

export class Item {
    id: number;
    feedId: number;
    uid: string;
    date: number;
    title: string;
    url: string;
    author: string;
    categories: Array<string>;
    excerptImage: string;
    excerpt: string;
    content: string;
    readingTime: number;
    readTime: number;
    readLater: boolean;
    saved: boolean;

    static fromJS(object: Item): Item {
        return new Item(
            object.id, object.feedId, object.uid, object.date, object.title, object.url, object.author,
            object.categories, object.excerptImage, object.excerpt, object.content, object.readingTime,
            object.readTime, object.readLater, object.saved
        );
    }

    constructor(id: number, feedId: number, uid: string, date: number, title: string, url: string, author: string,
                categories: Array<string>, excerptImage: string, excerpt: string, content: string, readingTime: number,
                readTime: number, readLater: boolean, saved: boolean) {
        makeAutoObservable(this);

        this.id = id;
        this.feedId = feedId;
        this.uid = uid;
        this.date = date;
        this.title = title;
        this.url = url;
        this.author = author;
        this.categories = categories;
        this.excerptImage = excerptImage;
        this.excerpt = excerpt;
        this.content = content;
        this.readingTime = readingTime;
        this.readTime = readTime;
        this.readLater = readLater;
        this.saved = saved;
    }
}

export default Item;
