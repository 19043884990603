import * as React from 'react';
import { action, makeObservable } from 'mobx';

class Select extends React.Component <SelectProps, {}> {
    constructor(props: SelectProps) {
        super(props);

        makeObservable(this, {
            selectChange: action.bound
        });
    }

    selectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <div className="select">
                <select onChange={this.selectChange}
                        value={this.props.value}>
                    {this.props.children}
                </select>
            </div>
        );
    }
}

interface SelectProps {
    onChange: (newValue: string) => void,
    value?: string
}

export default Select;
