import * as React from 'react';
import * as ItemModel from '../../../../../stores/model/Item';
import { IObservableArray } from 'mobx';
import Item from './Item';

const List: React.FunctionComponent<{ items: IObservableArray<ItemModel.Item> }> = (props) => (
    <nav className="menu is-list">
        {
            (props.items == null || props.items.length === 0) ?
                (<div><p>Keine Eintr&auml;ge</p></div>) :
                (props.items.map((item) => {
                    return (<Item key={item.id} item={item}/>);
                }))
        }
        <div style={{height: '10vh'}}/>
    </nav>
);

export default List;
