import * as React from 'react';

const AddonFormField: React.FunctionComponent<{ label: string, addon: string }> = (props) => (
    <div className="field is-horizontal">
        <div className="field-label is-normal">
            <label className="label">{props.label}</label>
        </div>
        <div className="field-body">
            <div className="field has-addons">
                <p className="control">
                    {props.children}
                </p>
                <p className="control">
                    <a className="button is-static" href="#/">
                        {props.addon}
                    </a>
                </p>
            </div>
        </div>
    </div>
);

export default AddonFormField;