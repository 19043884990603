export class FeedAndCount {
    id: number;
    categoryId: number;
    name: string;
    favicon: string | null;
    faviconMime: string | null;
    count: number;

    static fromJS(object: FeedAndCount): FeedAndCount {
        return new FeedAndCount(
            object.id, object.categoryId, object.name,
            object.favicon, object.faviconMime, object.count
        );
    }

    constructor(id: number, categoryId: number, name: string,
                favicon: string | null, faviconMime: string | null, count: number) {
        this.id = id;
        this.categoryId = categoryId;
        this.name = name;
        this.favicon = favicon;
        this.faviconMime = faviconMime;
        this.count = count;
    }
}