import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
// Styles
import './style/style.scss';
// Pages
import Login from './components/Login';
import Page404 from './components/Page404';
import Page500 from './components/Page500';
import App from './components/App';
import Loading from './components/Loading';
import RootStore from './stores/RootStore';
import ScrollToTop from './components/ScrollToTop';
import { configure } from 'mobx';

const rootStore = new RootStore();

const router = (
    <React.StrictMode>
        <Provider rootStore={rootStore}>
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <Route exact={true} path="/login" component={Login}/>
                        <Route exact={true} path="/404" component={Page404}/>
                        <Route exact={true} path="/500" component={Page500}/>
                        <Route exact={true} path="/auth/redirect" component={Loading}/>
                        <Route path="/" component={App}/>
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

configure({enforceActions: "observed"});

render(router, document.getElementById('app-root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
