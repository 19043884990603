import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../stores/RootStore';
import { action, makeObservable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../bulma';
import classNames from 'classnames';

@inject('rootStore')
@observer
class ItemMode extends React.Component<RootStoreProp, {}> {
    constructor(props: RootStoreProp) {
        super(props);

        makeObservable(this, {
            itemViewMode: action
        });
    }

    itemViewMode(viewMode: string) {
        window.scrollTo(0, 0);
        this.props.rootStore!.uiStateStore.itemViewMode = viewMode;
    }

    render() {
        const uiState = this.props.rootStore!.uiStateStore;

        return (
            <div className={classNames('navbar-item', {'is-hidden': uiState.itemLayout === 'columns'})}>
                <div className="buttons has-addons">
                    <Button color="info" outlined={true} small={true}
                            selected={uiState.itemViewMode === 'expanded'}
                            onClick={() => this.itemViewMode('expanded')}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </Button>
                    <Button color="info" outlined={true} small={true}
                            selected={uiState.itemViewMode === 'collapsed'}
                            onClick={() => this.itemViewMode('collapsed')}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default ItemMode;
